const mathHelpers = {
    toRadians: (val: number) => {
        return (val * Math.PI) / 180;
    },

    distanceBetweenPoints: (lat1: number, lon1: number, lat2: number, lon2: number): number => {
        //source: https://www.html5rocks.com/en/tutorials/geolocation/trip_meter/

        const r = 6371e3; // metres
        const lat1Rad: number = mathHelpers.toRadians(lat1);
        const lat2Rad: number = mathHelpers.toRadians(lat2);
        const deltaLat: number = mathHelpers.toRadians(lat2 - lat1);
        const deltaLon: number = mathHelpers.toRadians(lon2 - lon1);

        const a: number =
            Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
        const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return r * c;
    },
};

export default mathHelpers;
