export enum OpenDateStatus {
    Full = 'full',
    Limited = 'limited',
    Open = 'open',
    Closed = 'closed',
}

export default interface IOpenDate {
    siteId: number;
    date: string;
    status: string;
    bookedSlots: number;
    totalSlots: number;
    pendingSlots: number;
    remainingSlots: number;
}
