import React from 'react';
import Text from 'antd/lib/typography/Text';
import numberHelpers from '../../helpers/number';
import { useTranslation } from 'react-i18next';
import { OpenDateStatus } from '../../interfaces/IOpenDate';

export interface IDateCellProps {
    today: boolean;
    thisMonth: boolean;
    selected: boolean;
    past: boolean;
    status: string | undefined;
    day: number;
    month: number;
    year: number;
    disabled: boolean;
    locale: string;
}

const SessionDateCell: React.FunctionComponent<IDateCellProps> = (props) => {
    const { t } = useTranslation();

    const classNames: string[] = ['ant-calendar-date'];
    let status: React.ReactElement = <React.Fragment></React.Fragment>;

    if (props.disabled) classNames.push('disabled');
    if (props.thisMonth) classNames.push('not-current-month');
    if (props.past) classNames.push('past-date');
    if (props.selected) classNames.push('selected');

    if (props.today) {
        classNames.push('statused');
        classNames.push('today');
        status = <Text className="status today">{t('statuses.today')}</Text>;
    }

    if (props.status !== undefined && props.status !== '') {
        classNames.push(props.status);
        classNames.push('statused');

        let statusKey = '';
        switch (props.status) {
            case OpenDateStatus.Full:
                statusKey = 'statuses.soldOut';
                break;
            case OpenDateStatus.Limited:
                statusKey = 'statuses.limited';
                break;
        }

        status = <Text className={`status ${props.status}`}>{t(statusKey)}</Text>;
    }

    return (
        <div className={classNames.join(' ')}>
            <Text className="date-value">{numberHelpers.toNumber(props.locale, props.day)}</Text>
            {status}
        </div>
    );
};

export default React.memo(SessionDateCell);
