import Logging from '../service/logging';

const errorHelpers = {
    handleErrors: (response: Response) => {
        if (!response.ok) {
            let error = Error(response.url);
            error.name = 'Status code: ' + response.status.toString();
            error.stack = response.statusText;
            Logging.LogError(error);
            throw error;
        }
        return response;
    },
};

export default errorHelpers;
