import './loading-spinner.scss';

import { ReactComponent as LoaderIcon } from '../../images/svg/icons-loader-arc.svg';
import Icon from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const LoadingSpinner: React.FunctionComponent = () => {
    return (
        <div className="zl-loading-spinner-container">
            <Spin indicator={<Icon component={LoaderIcon} spin />} size="large" />
        </div>
    );
};

export default LoadingSpinner;
