import Site from '../interfaces/ISite';
import LocalisedContent from '../interfaces/ILocalisedContent';
import BookingOptions from '../interfaces/IBookingOptions';
import errorHelper from '../helpers/errors';

class Sites {
    static GetSites = (): Promise<Array<Site>> => {
        return fetch('sites')
            .then((response) => errorHelper.handleErrors(response))
            .then((response) => response.json() as Promise<Array<Site>>);
    };

    static GetSitePolicies = (siteId: number): Promise<Array<LocalisedContent>> => {
        return fetch(`sites/policy/${siteId}`)
            .then((response) => errorHelper.handleErrors(response))
            .then((response) => response.json() as Promise<Array<LocalisedContent>>);
    };

    static GetSiteBookingOptions = (siteId: number, key: string | undefined): Promise<BookingOptions> => {
        return fetch(`sites/options/${siteId}?key=${key ? key : ''}`)
            .then((response) => errorHelper.handleErrors(response))
            .then((response) => response.json() as Promise<BookingOptions>);
    };
}

export default Sites;
