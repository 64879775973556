//import { useFlags } from "launchdarkly-react-client-sdk";
import loadable from '@loadable/component';
import { ConfigProvider, Layout, message } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router';

import { IApplicationState } from './store';
import LoadingSpinner from './components/loading-spinner/loading-spinner';
import TicketsPage from './pages/tickets/tickets';
import ProcessingPage from './pages/processing/processing';
import NavProgress from './components/nav-progress/nav-progress';
import GoogleTagManager from './helpers/googleTagManager';
import AppSettings from './service/appsettings';
import { actionCreators as AppSettingActions } from './store/AppSettingsState';
import LogRocket from 'logrocket';
// import AppInsightsInitializer from './Logging/appInsightsInitializer';
// import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
// import { appInsightsReactPlugin } from "./Logging/appInsights";

import { actionCreators as AttributionActions } from './store/Attribution';

import 'moment/locale/en-au';
import 'moment/locale/en-gb';
import 'moment/locale/ja';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/ko';
import 'moment/locale/zh-tw';
import 'moment/locale/zh-cn';
import 'moment/locale/th';
import 'moment/locale/de';
import 'moment/locale/ar-sa';
import 'moment/locale/fr';
import 'moment/locale/ca';
import 'moment/locale/fr-ca';
import 'moment/locale/nl';
import 'moment/locale/it';

import ja_JP from 'antd/lib/locale-provider/ja_JP';
import es_ES from 'antd/lib/locale-provider/es_ES';
import en_US from 'antd/lib/locale-provider/en_US';
import pt_PT from 'antd/lib/locale-provider/pt_PT';
import ko_KR from 'antd/lib/locale-provider/ko_KR';
import zh_TW from 'antd/lib/locale-provider/zh_TW';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import th_TH from 'antd/lib/locale-provider/th_TH';
import de_DE from 'antd/lib/locale-provider/de_DE';
import ar_SA from 'antd/lib/locale-provider/ar_EG';
import fr_FR from 'antd/lib/locale-provider/fr_FR';
import ca_ES from 'antd/lib/locale-provider/ca_ES';
import nl_NL from 'antd/lib/locale-provider/nl_NL';
import it_IT from 'antd/lib/locale-provider/it_IT';
import useQuery from './hooks/useQuery';
import BrazeEvents from './helpers/braze';

import('smoothscroll-polyfill').then((smoothscroll) => {
    smoothscroll.polyfill();
});

const { Content } = Layout;

const App: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const rtl = useSelector((state: IApplicationState) => state.intl.rtl);
    const currentLocale = useSelector((state: IApplicationState) => state.intl.currentLocale);
    const selectedSite = useSelector((state: IApplicationState) => state.sites.selectedSite);
    const selectedSiteGtmKey = useSelector((state: IApplicationState) => state.sites.selectedSiteGtmKey);
    const selectedPlayers = useSelector((state: IApplicationState) => state.players.selectedPlayers);
    const bookingFailureMessage = useSelector((state: IApplicationState) => state.booking.failedMessageKey);
    const direction = rtl ? 'rtl' : 'ltr';
    const csp = { nonce: 'zerolatencycsp' };
    const query = useQuery();
    const dispatch = useDispatch();
    const globalGtmEndpoint = useSelector((state: IApplicationState) => state.appSettings.gtmEndpoint);
    const logRocketId = useSelector((state: IApplicationState) => state.appSettings.logRocketId);
    const gtmSendToClients = useSelector((state: IApplicationState) => state.appSettings.gtmSendToClients);
    const brazeApiKey = useSelector((state: IApplicationState) => state.appSettings.brazeApiKey);
    const brazeEndpoint = useSelector((state: IApplicationState) => state.appSettings.brazeEndpoint);
    const newsletterGroup = useSelector((state: IApplicationState) => state.appSettings.newsletterEmailGroup);
    const updatesGroup = useSelector((state: IApplicationState) => state.appSettings.updatesEmailGroup);
    const offersGroup = useSelector((state: IApplicationState) => state.appSettings.offersEmailGroup);
    const auSmsGroup = useSelector((state: IApplicationState) => state.appSettings.auSmsGroup);
    const appSettingsAreLoaded = useSelector((state: IApplicationState) => state.appSettings.settingsLoaded);

    const CheckoutComponent = loadable(() => import('./pages/checkout/checkout'), { fallback: <LoadingSpinner /> });
    const CompleteComponent = loadable(() => import('./pages/complete/complete'), { fallback: <LoadingSpinner /> });
    const DetailsComponent = loadable(() => import('./pages/details/details'), { fallback: <LoadingSpinner /> });
    const NotFoundComponent = loadable(() => import('./pages/not-found/not-found'), { fallback: <LoadingSpinner /> });

    const ErrorDisplayComponent = loadable(() => import('./components/error-display/error-display'), {
        fallback: <React.Fragment />,
    });

    const ReservationTimerComponent = loadable(() => import('./components/reservation-timer/reservation-timer'), {
        fallback: <React.Fragment />,
    });

    const isPlayerSelected = selectedPlayers !== undefined;

    useEffect(() => {
        // initialize the SDK
        if (!appSettingsAreLoaded) {
            console.log("skipping braze, app settings are not yet loaded");
            return;
        }

        BrazeEvents.Init(
            brazeApiKey,
            brazeEndpoint,
            newsletterGroup,
            updatesGroup,
            offersGroup,
            auSmsGroup
        );
    }, [appSettingsAreLoaded]);

    useEffect(() => {
        dispatch(AppSettingActions.loadSettings());
    }, [dispatch]);

    useEffect(() => {
        if (globalGtmEndpoint && globalGtmEndpoint !== "" && typeof globalGtmEndpoint != 'undefined') {
            GoogleTagManager.init(globalGtmEndpoint);
        }
    }, [globalGtmEndpoint]);

    useEffect(() => {
        // Load in UTM Data if there is any
        const utmCampaign = query.get('utm_campaign') ?? undefined;
        const utmContent = query.get('utm_content') ?? undefined;
        const utmId = query.get('utm_id') ?? undefined;
        const utmMedium = query.get('utm_medium') ?? undefined;
        const utmSource = query.get('utm_source') ?? undefined;
        const utmTerm = query.get('utm_term') ?? undefined;

        //the internal referal info eg from global website
        const int_Source = query.get('zl_s') ?? undefined;
        const int_SourcePage = query.get('zl_sp') ?? undefined;
        const int_ComponentType = query.get('zl_ct') ?? undefined;
        const int_ComponentInternalName = query.get('zl_cin') ?? undefined;
        const int_ComponentTitle = query.get('zl_cti') ?? undefined;
        const int_ButtonText = query.get('zl_bt') ?? undefined;


        if (utmId || utmCampaign || utmContent || utmMedium || utmSource || utmTerm) {
            dispatch(AttributionActions.utmLoaded(utmId, utmCampaign, utmContent, utmMedium, utmSource, utmTerm));
        }

        if (int_Source || int_SourcePage || int_ComponentType || int_ComponentInternalName || int_ComponentTitle || int_ButtonText) {
            dispatch(AttributionActions.internalAttributionLoaded(int_Source, int_SourcePage, int_ComponentType, int_ComponentInternalName, int_ComponentTitle, int_ButtonText));
        }

    }, []);

    useEffect(() => {
        const hubspotScript = document.createElement('script');

        hubspotScript.type = "text/javascript";
        hubspotScript.id = "hs-script-loader";
        hubspotScript.async = true;
        hubspotScript.defer = true;
        hubspotScript.src = `//js.hs-scripts.com/21452464.js`;

        document.head.appendChild(hubspotScript);

        return () => {
            document.head.removeChild(hubspotScript);
        }
    }, []);

    useEffect(() => {
        if (!appSettingsAreLoaded) {
            console.log("skipping gtm client key, app settings are not yet loaded");
            return;
        }

        if (typeof selectedSite == 'undefined' || typeof selectedSiteGtmKey == 'undefined' || selectedSiteGtmKey == '') {
            console.log("skipping gtm client key, selected site or client is not valid (site, key): (" + selectedSite + ', ' + selectedSiteGtmKey + ")");
            return;
        }

        if (!gtmSendToClients) {
            console.log("Not running init for newly selected site " + selectedSite?.name + "/" + selectedSite?.code
                + " gtm endpoint " + selectedSiteGtmKey
                + " because gtmSendToClients is " + gtmSendToClients);
            return;
        }

        if (gtmSendToClients) {
            GoogleTagManager.init(selectedSite?.siteMetadata.googleTagManagerKey, selectedSiteGtmKey);
        }

    }, [selectedSiteGtmKey, appSettingsAreLoaded]);

    useEffect(() => {
        if (logRocketId) {
            LogRocket.init(logRocketId);
        }
    }, [logRocketId]);

    useEffect(() => {
        if (bookingFailureMessage !== undefined) {
            const localisedMessage = t(bookingFailureMessage);
            message.error(localisedMessage);
        }
    }, [bookingFailureMessage, t]);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [location]);

    var configLocale = en_US;
    switch (currentLocale) {
        case 'ja-JP':
            configLocale = ja_JP;
            break;
        case 'es-ES':
            configLocale = es_ES;
            break;
        case 'pt-PT':
            configLocale = pt_PT;
            break;
        case 'ko-KR':
            configLocale = ko_KR;
            break;
        case 'zh-TW':
            configLocale = zh_TW;
            break;
        case 'zh-CN':
            configLocale = zh_CN;
            break;
        case 'th-TH':
            configLocale = th_TH;
            break;
        case 'de-DE':
            configLocale = de_DE;
            break;
        case 'ar-SA':
            configLocale = ar_SA;
            break;
        case 'fr-FR':
        case 'fr-CA':
            configLocale = fr_FR;
            break;
        case 'ca-ES':
            configLocale = ca_ES;
            break;
        case 'nl-NL':
            configLocale = nl_NL;
            break;
        case 'it-IT':
            configLocale = it_IT;
            break;
    }

    return (
        <React.Fragment>
            {/* <AppInsightsInitializer />
            <AppInsightsContext.Provider value={appInsightsReactPlugin}> */}
                <ErrorDisplayComponent />
                <Content className="nav-progress-container">
                    <NavProgress />
                </Content>
                <Content className="booking-timer">
                    <ReservationTimerComponent />
                </Content>
                <Content className="content-container">
                    <ConfigProvider direction={direction} locale={configLocale} csp={csp}>
                        <Switch>
                            <Route exact path="/">
                                <TicketsPage />
                            </Route>
                            <Route exact path="/book-now/:venue?/:accessCode?/:players?/:date?/:time?/:session?">
                                <TicketsPage />
                            </Route>
                            <Route exact path="/gift-voucher/:venue?/:amount?">
                                <TicketsPage />
                            </Route>
                            <Route exact path="/private-event/:venue?/:accessCode?/:players?/:date?/:time?/:session?">
                                <TicketsPage />
                            </Route>
                            <Route exact path="/checkout/:venue?">
                                <CheckoutComponent />
                            </Route>
                            <Route exact path="/complete/:venue?">
                                <CompleteComponent />
                            </Route>
                            <Route exact path="/booking/:id">
                                <DetailsComponent />
                            </Route>
                            <Route path="/processing">
                                <ProcessingPage />
                            </Route>
                            <Route path="*">
                                <NotFoundComponent />
                            </Route>
                        </Switch>
                    </ConfigProvider>
                </Content>
            {/* </AppInsightsContext.Provider> */}
        </React.Fragment>
    );
};

export default App;