import './time-picker.scss';

import { Col, Empty, Row } from 'antd';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Heading, { HeadingSize } from '../../components/heading/heading';
import SessionTime from '../../interfaces/ISessionTime';
import pluralHelper from '../../helpers/plural';
import { actionCreators as SessionActions } from '../../store/Sessions';
import { actionCreators as BookingActions, BookingStatus } from '../../store/Booking';
import LoadingSpinner from '../loading-spinner/loading-spinner';
import TimeButton from './time-button';
import { IApplicationState } from '../../store';
import { property } from 'lodash';

export interface ITimePickerProps {
    loading: boolean;
    dayTimes?: SessionTime[]; //presorted :)
    eveningTimes?: SessionTime[]; //presorted :)
    selectedTime?: SessionTime;
    locale: string;
    players?: number;
}

export interface ITimeButtonWrapperProps {
    time: SessionTime;
    selected: SessionTime | undefined;
    players: number | undefined;
    locale: string;
}

const TimePicker: React.FunctionComponent<ITimePickerProps> = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const pendingBooking = useSelector((state: IApplicationState) => state.booking.booking);
    const bookingStatus = useSelector((state: IApplicationState) => state.booking.status);

    const timeClicked = useCallback(
        (time: SessionTime) => {
            if (pendingBooking && bookingStatus === BookingStatus.Pending) {
                dispatch(BookingActions.expireBooking());
            }

            if (time) dispatch(SessionActions.postTimeSelectedAction(time));
        },
        [bookingStatus, dispatch, pendingBooking]
    );

    const NoDataState: React.FunctionComponent = () => {
        const emptyLogo = `/static/zl-black-new.png`;
        return (
            <Empty
                description={t('sessionDatePicker.labels.noDatesAvailable')}
                image={emptyLogo}
                imageStyle={{ opacity: 0.1, height: 50, margin: 20 }}
            />
        );
    };

    const TimeButtonWrapper: React.FunctionComponent<ITimeButtonWrapperProps> = (props) => {
        const isSelected = props.selected !== undefined && props.selected.time === props.time.time;
        
        const notEnoughPlayers = props.players !== undefined && props.time.openTimeMinimumPlayers > props.players;
        const tooManyPlayers = props.players !== undefined && props.time.slotsRemaining < props.players;
        const noSlotsLeft = props.time.slotsRemaining === 0;

        const disabled = noSlotsLeft || tooManyPlayers || notEnoughPlayers;


        const warningStatusText = !disabled ? ''
            : (noSlotsLeft ? t('statuses.soldOut') 
            : (tooManyPlayers ? t(pluralHelper.getKey(props.locale, 'statuses.spotsLeft', props.time.slotsRemaining), { count: props.time.slotsRemaining, })
            : (props.time.slotsRemaining === props.time.openTimeMinimumPlayers ? t('statuses.players_requirement', { count: props.time.slotsRemaining })
            : t('statuses.players_range_requirement', { maximum: props.time.slotsRemaining, minimum: props.time.openTimeMinimumPlayers }))));

        return (
            <TimeButton
                item={props.time}
                selected={isSelected}
                disabled={disabled}
                soldOut={noSlotsLeft}
                warningText={warningStatusText}
                onClick={timeClicked}
            />
        );
    };

    if (props.loading) return <LoadingSpinner />;
    if (props.dayTimes === undefined && props.eveningTimes === undefined) return <NoDataState />;
    if (props.dayTimes?.length === 0 && props.eveningTimes?.length === 0) return <NoDataState />;

    const dayValues: React.ReactElement =
        props.dayTimes !== undefined && props.dayTimes.length > 0 ? (
            <React.Fragment>
                <Row justify="start" align="top">
                    <Col span={24}>
                        <Heading level={HeadingSize.Four} className="time-heading">
                            {t('sessionTimePicker.titles.daySession')}
                        </Heading>
                    </Col>
                </Row>
                <Row justify="space-around" align="top" className="zl-time-picker-container day">
                    <Col span={24}>
                        {props.dayTimes.map((sessionTime: SessionTime) => (
                            <TimeButtonWrapper
                                time={sessionTime}
                                selected={props.selectedTime}
                                players={props.players}
                                locale={props.locale}
                                key={sessionTime.time}
                            />
                        ))}
                    </Col>
                </Row>
            </React.Fragment>
        ) : (
            <React.Fragment />
        );

    const eveningValues: React.ReactElement =
        props.eveningTimes !== undefined && props.eveningTimes.length > 0 ? (
            <React.Fragment>
                <Row justify="start" align="top">
                    <Col span={24}>
                        <Heading level={HeadingSize.Four} className="time-heading">
                            {t('sessionTimePicker.titles.eveningSession')}
                        </Heading>
                    </Col>
                </Row>
                <Row justify="space-around" align="top" className="zl-time-picker-container night">
                    <Col span={24}>
                        {props.eveningTimes.map((sessionTime: SessionTime) => (
                            <TimeButtonWrapper
                                time={sessionTime}
                                selected={props.selectedTime}
                                players={props.players}
                                locale={props.locale}
                                key={sessionTime.time}
                            />
                        ))}
                    </Col>
                </Row>
            </React.Fragment>
        ) : (
            <React.Fragment />
        );

    return (
        <React.Fragment>
            {dayValues}
            {eveningValues}
        </React.Fragment>
    );
};

export default React.memo(TimePicker);
