import React from 'react';
import Text from 'antd/lib/typography/Text';
import Icon from '@ant-design/icons';
import ZLButton, { ButtonSize, ButtonType } from '../zl-button/zl-button';
import { ReactComponent as arrowForward } from '../../images/svg/icons-arrow-forward.svg';

export interface ISummaryCheckoutProps {
    enable: boolean;
    loading: boolean;
    text: React.ReactNode;
    onCheckout: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const SummaryCheckout: React.FunctionComponent<ISummaryCheckoutProps> = (props) => {
    const { enable, loading, text, onCheckout } = props;
    if (!enable) return <React.Fragment />;

    return (
        <ZLButton
            type={ButtonType.Primary}
            onClick={onCheckout}
            className="checkout"
            size={ButtonSize.Large}
            loading={loading}
            block
        >
            <Text>{text}</Text>
            <Icon component={arrowForward} className="zl-icon" />
        </ZLButton>
    );
};

export default React.memo(SummaryCheckout);
