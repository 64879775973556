import { Action, Reducer } from 'redux';
import { IAnalyticsAction } from './';
import { GoogleTagManagerEvents } from '../helpers/googleTagManager';

export enum PurchaseTypes {
    Booking = 0,
    GiftVoucher = 1,
    PrivateEvent = 2
}

export interface IPurchaseTypeState {
    selectedPurchaseType: PurchaseTypes;
    selectedPurchaseTypeUI: boolean;
}

export const PURCHASE_TYPE_SELECTED: 'PURCHASE_TYPE_SELECTED' = 'PURCHASE_TYPE_SELECTED';
export const CLEAR_PURCHASE_TYPE: 'CLEAR_PURCHASE_TYPE' = 'CLEAR_PURCHASE_TYPE';

export interface ISelectPurchaseTypeAction extends IAnalyticsAction {
    type: typeof PURCHASE_TYPE_SELECTED;
    purchaseType: PurchaseTypes;
}

export interface IPurchaseTypeSelectedAction {
    type: typeof CLEAR_PURCHASE_TYPE;
}

const undefinedState: IPurchaseTypeState = {
    selectedPurchaseType: PurchaseTypes.Booking,
    selectedPurchaseTypeUI: false,
}

export type KnownPurchaseTypeActions =
    | ISelectPurchaseTypeAction
    | IPurchaseTypeSelectedAction;

export const actionCreators = {
    selectPurchaseType: (purchaseType: PurchaseTypes) =>
    ({
        type: PURCHASE_TYPE_SELECTED,
        purchaseType: purchaseType,
        googleTagManagerEvent: GoogleTagManagerEvents.PurchaseTypeSelected(PurchaseTypes[purchaseType]),
    } as ISelectPurchaseTypeAction),

    clearPurchaseType: () => ({ type: CLEAR_PURCHASE_TYPE }),
};

export const reducer: Reducer<IPurchaseTypeState> = (
    state: IPurchaseTypeState | undefined,
    incomingAction: Action
): IPurchaseTypeState => {
    const action: KnownPurchaseTypeActions = incomingAction as KnownPurchaseTypeActions;
    if (state === undefined) return undefinedState;

    switch (action.type) {

        case PURCHASE_TYPE_SELECTED: {
            return {
                selectedPurchaseType: action.purchaseType,
                selectedPurchaseTypeUI: true,
            }
        }

        case CLEAR_PURCHASE_TYPE: {
            return {
                ...undefinedState,
            };
        }

        default:
            return state;
    }
};