import React from 'react';
import Text from 'antd/lib/typography/Text';

export interface IGamePackageInfoProps {
    playersValue: string;
    playersLabel: string;
    expMinsValue?: React.ReactNode;
    expMinsLabel?: string;
    perPersonValue?: React.ReactNode;
    perPersonLabel?: string;
    ageValue: React.ReactNode | undefined;
    ageLabel: string | undefined;
    gameInfo?: boolean;
}

const GamePackageInfo: React.FunctionComponent<IGamePackageInfoProps> = (props) => {

    let detailsCount = 0;
    if(props.playersValue !== undefined) detailsCount++;
    if(props.expMinsValue !== undefined) detailsCount++;
    if(props.perPersonValue !== undefined) detailsCount++;
    if(props.ageValue !== undefined) detailsCount++;

    const detailCountClass = 'package-detail ' + (detailsCount == 1 ? 'one-detail' : (detailsCount == 2 ? 'two-details' : (detailsCount == 3 ? 'three-details' : 'four-details'))) + (props.gameInfo ? '-info' : '');

    const playersDisplay = props.playersValue === undefined ? '' : 
        (<div className={detailCountClass}>
            <Text className="value">{props.playersValue}</Text>
            <Text className="label">{props.playersLabel}</Text>
        </div>);

    const durationDisplay = props.expMinsValue === undefined ? '' : 
        (<div className={detailCountClass}>
            <Text className="value">{props.expMinsValue}</Text>
            <Text className="label">{props.expMinsLabel}</Text>
        </div>);

    const costDisplay = props.perPersonValue === undefined ? '' : 
        (<div className={detailCountClass}>
            <Text className="value">{props.perPersonValue}</Text>
            <Text className="label">{props.perPersonLabel}</Text>
        </div>);

    const ageDisplay = props.ageValue === undefined ? '' : 
        (<div className={detailCountClass}>
            <Text className="value">{props.ageValue}</Text>
            <Text className="label">{props.ageLabel}</Text>
        </div>);

    return (
        <React.Fragment>
            {playersDisplay}
            {durationDisplay}
            {costDisplay}
            {ageDisplay}
        </React.Fragment>
    );
};

export default React.memo(GamePackageInfo);
