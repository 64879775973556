import React from 'react';

const Scroll = {
    scrollTo: (ref: React.RefObject<HTMLElement>, scrollOptions?: ScrollToOptions) => {
        if (ref === null || ref.current === null || ref.current.getBoundingClientRect === undefined) return;

        const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
        if (!supportsNativeSmoothScroll) return;

        const scrollWait = 250;
        const boundingRect = ref.current.getBoundingClientRect();
        const top = boundingRect.top;
        const scrollToOptions: ScrollToOptions = scrollOptions ?? {
            top: top,
            left: 0,
            behavior: 'smooth',
        };

        setTimeout(() => {
            window.scrollTo(scrollToOptions);
        }, scrollWait);
    },
};

export default Scroll;
