import './site-picker.scss';

import { Col, Row, Modal } from 'antd';
import find from 'lodash/find';
import moment from 'moment';
import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import mathHelpers from '../../helpers/math';
import usePrevious from '../../hooks/usePrevious';
import Site from '../../interfaces/ISite';
import { IApplicationState } from '../../store';
import { actionCreators as SessionActions } from '../../store/Sessions';
import { actionCreators as SiteActions } from '../../store/Sites';
import { actionCreators as BookingActions } from '../../store/Booking';
import { actionCreators as GiftVoucherActions } from '../../store/GiftVoucher';
import ZLLocation from '../location-finder/location-finder';
import SiteDropdown from '../site-dropdown/site-dropdown';
import LoadingSpinner from '../loading-spinner/loading-spinner';
import Text from 'antd/lib/typography/Text';
import Heading, { HeadingSize } from '../heading/heading';
import { push } from 'connected-react-router';
import Coords from '../../interfaces/ICoords';
import { actionCreators as PlayerActions } from '../../store/Players';

import ReactGA from 'react-ga';
import { PurchaseTypes } from '../../store/PurchaseTypes';
import Logging from '../../service/logging';
import BrazeEvents from '../../helpers/braze';

export interface ISitePickerProps {
    loading: boolean;
}

const SitePicker: React.FunctionComponent<ISitePickerProps> = (props) => {
    const { loading } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const sites = useSelector((state: IApplicationState) => state.sites.sites);
    const nearestSite = useSelector((state: IApplicationState) => state.sites.nearestSite);
    const selectedSite = useSelector((state: IApplicationState) => state.sites.selectedSite);
    const preSelectedSite = useSelector((state: IApplicationState) => state.sites.preselectedSite);
    const location = useSelector((state: IApplicationState) => state.location.location);
    const appliedCodes = useSelector((state: IApplicationState) => state.booking.appliedCodes);
    const preSelectedCode = useSelector((state: IApplicationState) => state.booking.preSelectedCode);
    const accessCode = useSelector((state: IApplicationState) => state.sites.accessCode);
    const selectedGame = useSelector((state: IApplicationState) => state.games.selectedGame);
    const prevProps = usePrevious({ location });

    const selectedPurchaseType = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseType);

    const packageTypeId = selectedPurchaseType == PurchaseTypes.Booking ? 1 : 2;

    const findNearestSite = (sites: Array<Site>, location?: Coords) => {
        let nearest = sites[0];
        sites.forEach((site: Site) => {
            if (location != null) {
                const locationLatitude = location.latitude;
                const locationLongitude = location.longitude;

                if (
                    site.siteMetadata.longitude != null &&
                    site.siteMetadata.latitude != null &&
                    nearest.siteMetadata.latitude != null &&
                    nearest.siteMetadata.longitude != null
                ) {
                    const distanceToSite: number = mathHelpers.distanceBetweenPoints(
                        locationLatitude,
                        locationLongitude,
                        site.siteMetadata.latitude,
                        site.siteMetadata.longitude
                    );
                    const distanceToNearest: number = mathHelpers.distanceBetweenPoints(
                        locationLatitude,
                        locationLongitude,
                        nearest.siteMetadata.latitude,
                        nearest.siteMetadata.longitude
                    );
                    nearest = distanceToSite < distanceToNearest ? site : nearest;
                }
            }
        });

        return nearest;
    };

    let trackers: string[] = ['zerolatency'];

    const siteChanged = useCallback(
        (site: Site, nearest: boolean) => {
            dispatch(SessionActions.clearSelectedDateTime());
            dispatch(SiteActions.siteSelected(site, nearest));
            dispatch(BookingActions.clearBooking());
            dispatch(GiftVoucherActions.clearGiftVoucher());
            dispatch(GiftVoucherActions.getGiftVoucherConfig(site.siteId));
            BrazeEvents.VenueSelected(site);
            Logging.SetGlobalSiteKey(site.code);

            //if there are any codes currently applied or if a preselected code was provided and the preselected site isn't the current site.
            //the second condition is because codes are site specific.
            if (appliedCodes.length > 0 || (preSelectedCode && preSelectedSite && site.urlKey !== preSelectedSite && site.siteId !== parseInt(preSelectedSite))) {
                dispatch(BookingActions.clearCode());
            }

            if (site.useExternalBookingUrl) {
                dispatch(PlayerActions.clearPlayers());

                const modal = Modal.confirm({
                    icon: undefined,
                    centered: true,
                    maskClosable: true,
                    okCancel: true,
                    autoFocusButton: null,
                    width: 'auto',
                    cancelText: t('sitePicker.thirdParty.cancel'),
                    cancelButtonProps: {
                        type: 'link',
                        className: 'cancel',
                    },
                    okType: 'default',
                    okText: t('sitePicker.thirdParty.okay'),
                    title: <Heading level={HeadingSize.One}>{t('sitePicker.thirdParty.title')}</Heading>,
                    className: 'sites-modal-container',
                    content: <Text>{t('sitePicker.thirdParty.message')}</Text>,
                    onOk: () => {
                        ReactGA.outboundLink(
                            { label: 'Redirecting to External Site' },
                            function () {
                                window.location.href = site.externalBookingUrl;
                            }, trackers
                          );
                    },
                    onCancel: () => {
                        modal.destroy();
                    },
                });

                if (selectedPurchaseType === PurchaseTypes.Booking) {
                    dispatch(push(`/book-now/${site.urlKey}/${accessCode ?? ''}${window.location.search}`));
                }

                return;
            }

            if (selectedPurchaseType === PurchaseTypes.Booking) {
                dispatch(push(`/book-now/${site.urlKey}/${accessCode ?? ''}`));
            }
            else if (selectedPurchaseType === PurchaseTypes.GiftVoucher) {
                dispatch(push(`/gift-voucher/${site.urlKey}`));
            }
            else if (selectedPurchaseType === PurchaseTypes.PrivateEvent) {
                dispatch(push(`/private-event/${site.urlKey}`));
            }

            const start: string = moment().locale('en').format('YYYY-MM-DD');
            const end: string = moment().locale('en').add(2, 'months').endOf('month').format('YYYY-MM-DD');
            dispatch(SessionActions.loadOpenDates(site.siteId, start, end, accessCode, selectedGame?.experienceId, packageTypeId));
        },
        [appliedCodes, preSelectedCode, preSelectedSite, selectedPurchaseType, packageTypeId, selectedGame, accessCode, dispatch, t]
    );

    const sitesDropdownChanged = (value: number, option: object[] | object) => {
        const site: Site | undefined = find(sites, (site: any) => parseInt(site.siteId) === value);

        if (site) {
            siteChanged(site, false);
        }
    };

    useEffect(() => {
        if (selectedSite === undefined && preSelectedSite && sites) {
            const preselectedSiteId = parseInt(preSelectedSite);
            const targetSite = sites.find((x) => x.urlKey === preSelectedSite || x.siteId === preselectedSiteId);
            if (targetSite) siteChanged(targetSite, false);
        }
    }, [preSelectedSite, sites, siteChanged, selectedSite]);

    useEffect(() => {
        if (prevProps !== undefined && prevProps.location !== location) {
            if (sites) {
                const nearest: Site = findNearestSite(sites, location);

                if (nearest != null) {
                    siteChanged(nearest, true);
                }
            }
        }
    }, [prevProps, location, sites, siteChanged]);

    if (loading) return <LoadingSpinner />;

    return (
        <React.Fragment>
            <Row justify="start" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <SiteDropdown
                        sites={sites}
                        selectedSite={selectedSite}
                        closest={nearestSite}
                        onChange={sitesDropdownChanged}
                        value={selectedSite?.siteId ?? undefined}
                        closestVenueLabel={t('sitePicker.hint.closestVenue')}
                        placeholder={t('sitePicker.control.placeholder')}
                    />
                </Col>
            </Row>
            <Row justify="start" align="top">
                <Col span={24}>
                    <ZLLocation>{t('sitePicker.hint.useBrowserLocation')}</ZLLocation>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SitePicker;
