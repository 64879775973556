import './zl-button.scss';

import { ReactComponent as LoaderIcon } from '../../images/svg/icons-loader-arc.svg';
import Icon from '@ant-design/icons';
import { Button, Spin } from 'antd';
import React from 'react';

import ReactGA from 'react-ga';

export interface IZLButtonProps {
    href?: string;
    htmlType?: 'button' | 'reset' | 'submit' | undefined;
    title?: string;
    children: React.ReactNode | string;
    glowing?: boolean;
    disabled?: boolean;
    ghost?: boolean;
    loading?: boolean;
    tall?: boolean; //adds height to the button to match zl-input large size
    block?: boolean;
    className?: string;
    selected?: boolean;
    type?: ButtonType;
    shape?: ButtonShape;
    size?: ButtonSize;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export enum ButtonType {
    Primary = 'primary',
    Default = 'default',
    Dashed = 'dashed',
    Link = 'link',
    Ghost = 'ghost',
}

export enum ButtonSize {
    Large = 'large',
    Default = 'middle',
    Small = 'small',
}

export enum ButtonShape {
    Circle = 'circle',
    CircleOutline = 'circle-outline',
    Round = 'round',
}

const ZLButton: React.FunctionComponent<IZLButtonProps> = (props) => {
    const buttonClasses = (): string => {
        const classNames = props.className ? props.className : '';
        const classes: Array<string> = [classNames];

        if (props.selected) classes.push('selected');
        if (props.glowing) classes.push('glowing');
        if (props.tall) classes.push('tall');
        return classes.join(' ');
    };

    const contents = props.loading ? (
        <Spin indicator={<Icon component={LoaderIcon} spin className="zl-icon" />} size="large" />
    ) : (
        props.children
    );

    function outboundLinkClicked(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        ReactGA.outboundLink(
            { label: 'Redirecting to External Page' },
            function () {
                if(props.href) {
                    window.open(props.href, '_blank')?.focus();
                }
            }, ['zerolatency']
        );

        if(props.onClick){
            props.onClick(event);
        }
    }

    return (
        <Button
            className={`${buttonClasses()}`}
            htmlType={props.htmlType}
            type={props.type ? props.type : ButtonType.Default}
            shape={props.shape ? props.shape : undefined}
            size={props.size ? props.size : ButtonSize.Default}
            title={props.title ? props.title : ''}
            onClick={props.href ? outboundLinkClicked : props.onClick}
            disabled={props.disabled}
            ghost={props.ghost}
            block={props.block}
        >
            {contents}
        </Button>
    );
};

export default ZLButton;
