import './number-picker.scss';

import { Button, Col, Form, Row } from 'antd';
import { Input } from 'antd';
import React, { useCallback } from 'react';
import Text from 'antd/lib/typography/Text';
import ZLButton, { ButtonSize, ButtonType } from '../zl-button/zl-button';

export interface INumberPickerProps {
    playerCount: number;
    min: number;
    max: number;
    locale: string;
    onChange?: (changedValue: number) => void;
}

const NumberPicker: React.FunctionComponent<INumberPickerProps> = (props) => {

    const incrementClicked = useCallback(() => {
        if (props.onChange) props.onChange(props.playerCount + 1);
    }, [props.onChange, props.playerCount]);

    const decrementClicked = useCallback(() => {
        if (props.onChange) props.onChange(props.playerCount - 1);
    }, [props.onChange, props.playerCount]);

    const updatePlayerCount = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        let newNumber = parseInt(event.currentTarget.value);
        if(newNumber > props.max) {
            newNumber = props.max;
        }
        if(newNumber < props.min) {
            newNumber = props.min;
        }
        if(props.onChange) {
            props.onChange(newNumber);
        }
    };

    const incrementButton = (
        <Button
            type={ButtonType.Default}
            size={ButtonSize.Large}
            style={{ margin: 0, border: 'none', width: '52px', height: '52px' }}
            className="zl-number-button"
            block
            disabled={props.playerCount >= props.max}
            onClick={incrementClicked}>
            <Text>+</Text>
        </Button>);

    const decrementButton = (
        <Button
            type={ButtonType.Default}
            size={ButtonSize.Large}
            style={{ margin: 0, border: 'none', width: '52px', height: '52px' }}
            className="zl-number-button"
            block
            disabled={props.playerCount <= props.min}
            onClick={decrementClicked}>
            <Text>-</Text>
        </Button>);

    return (
        <section>
            <Row justify="start" align="top" className="zl-number-picker-container">
                <Col span={24}>
                    <Form>
                        <Form.Item
                            className={`zl-form-item`}>
                            <Input
                                addonBefore={decrementButton}
                                addonAfter={incrementButton}
                                style={{ width: '350px', textAlign: 'center' }}
                                className="zl-input tall"
                                type='number'
                                max={props.max}
                                min={props.min}
                                value={props.playerCount}
                                onChange={updatePlayerCount}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </section>
    );
};

export default NumberPicker;
