import './heading.scss';

import React from 'react';

export interface IHeadingProps {
    level?: HeadingSize;
    children: React.ReactNode | string;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export enum HeadingSize {
    One = 1,
    Two = 2,
    Three = 3,
    Four = 4,
    Five = 5,
    Six = 6,
}

const Heading: React.FunctionComponent<IHeadingProps> = (props) => {
    const level: HeadingSize = props.level ? props.level : HeadingSize.One;

    const classNames: string = props.className ? props.className : '';

    return React.createElement(`h${level}`, { className: classNames, onClick: props.onClick }, [props.children]);
};

export default Heading;
