import errorHelper from '../helpers/errors';

export interface IAppSettingsList {
    environment: string;
    gtmEndpoint: string;
    gtmSendToClients: boolean;
    logRocketId: string;
    brazeApiKey: string;
    brazeEndpoint: string;
    newsletterEmailGroup: string;
    updatesEmailGroup: string;
    offersEmailGroup: string;
    auSmsGroup: string;
    appInsightsKey: string;
}

class AppSettings {
    static GetAppSettings = (): Promise<IAppSettingsList> => {
        return fetch(`AppSettings/All/`)
            .then((response) => errorHelper.handleErrors(response))
            .then((response) => response.json() as Promise<IAppSettingsList>);
    };
}

export default AppSettings;
