import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';

interface IPackageDescriptionProps {
    recentTicketsSold: number;
}

const PackageScarcity: React.FunctionComponent<IPackageDescriptionProps> = (props) => {
    const { t } = useTranslation();
    const fireIcon = `/static/fire.png`;

    // if (props.recentTicketsSold < 20)
        return (null);

    // const popular = props.recentTicketsSold >= 50 ? <img src={fireIcon} className="scarcity-image" alt={t('packagePicker.labels.popular')} /> : null;

    // return (
    //     <Row justify="end" align="bottom" className="package-scarcity">
    //         <Col>
    //             <Text className="scarcity-text">
    //                 {t('packagePicker.labels.scarcity', { count: props.recentTicketsSold })}
    //             </Text>
    //             {popular}
    //         </Col>
    //     </Row>
    // );
};

export default React.memo(PackageScarcity);
