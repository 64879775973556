import './game-package.scss';

import { Col, Collapse, Row } from 'antd';
import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import PackageTierPrice from '../../interfaces/IPackageTierPrice';
import Session from '../../interfaces/ISession';
import Site from '../../interfaces/ISite';
import { actionCreators as SessionActions } from '../../store/Sessions';
import GamePackageDetailsButton from './package-details-button';
import PackageDescription from './package-description';
import GamePackageInfo from './package-info';
import GamePackageImage from './package-image';
import GamePackageHeader from './package-header';
import GamePackageAddButton from './package-add-button';
import GamePackageScarcity from './package-scarcity';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation, Trans } from 'react-i18next';
import numberHelpers from '../../helpers/number';
import Text from 'antd/lib/typography/Text';
import Currency from '../currency/currency';
import PackagePrivacy from './package-privacy';
import { IPriceSet } from '../../interfaces/IPriceSet';

const { Panel } = Collapse;

export interface IGamePackageProps {
    session: Session;
    site?: Site;
    isSelected: boolean;
    locale: string;
    priceSet: IPriceSet | undefined;
    selectedPlayers: number | undefined;
    selectedPrivateUpgrade: boolean;
    onClick?: (session: Session) => void;
    onUpgradeCheckChanged?: (checked: boolean) => void;
}

const GamePackage: React.FunctionComponent<IGamePackageProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const breakpoint = useBreakpoint();
    const { session, site, isSelected, locale, onClick, priceSet } = props;
    const [panelOpen, setPanelOpen] = useState(false);
    const noImage = `https://zerolatencyvr.azureedge.net/booking-engine/${session.siteId}/no-package-image-header.jpg`;

    const activeKey = panelOpen ? `${session.sessionId}-${session.packageId}` : `-1`;
    const fallbackLanguage = site?.defaultLocale;

    const languageData =
        session.package.languageData.find((language) => language.locale === locale) ??
        session.package.languageData.find((language) => language.locale === fallbackLanguage);

    const packageTierPrice = session.package.packageTierPrices.find((packageTierPrice: PackageTierPrice) => {
        return packageTierPrice.priceTierType.priceTierTypeId === session.priceTierTypeId;
    });

    const headerImage = session.package.headerImage
        ? session.package.headerImage : 
        `https://zerolatencyvr.azureedge.net/booking-engine/packages/${session.package.header}.jpg`;

    const sessionSelected = useCallback(() => {
        if (onClick) onClick(session);
    }, [onClick, session]);

    const togglePanel = useCallback(() => {
        const newOpenState = !panelOpen;

        if (newOpenState) {
            dispatch(SessionActions.postPackageDetailsViewedAction(session));
        }

        setPanelOpen(newOpenState);
    }, [dispatch, panelOpen]);

    if (session == null) return <React.Fragment></React.Fragment>;
    if (session.package == null) return <React.Fragment></React.Fragment>;

    const minimumAge = session.package.minimumAge > 0 ? t('packagePicker.labels.ageMin',  { age: session.package.minimumAge }) : undefined;
    const perPersonLabel = session.package.perPersonPricing ? t('packagePicker.labels.perPerson') : t('packagePicker.labels.fixedPrice');

    const unbookedSlots = session.maximumSlots == session.remainingSlots ? session.maximumSlots - (props.selectedPlayers ?? 0) : 0;
    const privateBookingCost = priceSet === undefined ? packageTierPrice!.price.listPrice * unbookedSlots * session.package.privacyChargePercentage / 100 : 0;

    //sometimes the hook reports multiple breakpoints, so this seems messy but...
    if (breakpoint.md || breakpoint.lg || breakpoint.xxl)
        return (
            <Row justify="start" align="top" className="zl-session" key={session.sessionName}>
                <Col span={24}>
                    <Row justify="start" align="bottom">
                        <Col span={24} className="session-header">
                            <GamePackageImage
                                session={session}
                                imageUrl={headerImage}
                                fallbackImage={noImage}
                                packageName={languageData?.name ?? session.package.alias}
                                modalOkText={t('packagePicker.labels.close')}
                                videoUrl={session.package.videoUrl}
                            />
                            <GamePackageHeader
                                tagline={session.package.attractionTypeId > 1 ? session.gameSpace.attractionTypeName : t('packagePicker.labels.virtualRealityExperience')}
                                packageName={languageData?.name ?? session.package.alias}
                                accessCode={session.accessCode}
                            />
                        </Col>
                    </Row>
                    <Row justify="start" align="middle" className="desktop-details-row">
                        <Col span="auto" className="package-details">
                            <GamePackageInfo
                                playersValue={t('packagePicker.labels.playersMinMax', {
                                    min: `${numberHelpers.toNumber(locale, session.minimumSlots)}`,
                                    max: `${numberHelpers.toNumber(locale, session.maximumSlots)}`,
                                })}
                                playersLabel={t('packagePicker.labels.players')}
                                expMinsValue={
                                    <Trans
                                        i18nKey="packagePicker.labels.minutes"
                                        values={{
                                            minutes: `${numberHelpers.toNumber(
                                                locale,
                                                session.package.experienceDurationMinutes
                                            )}`,
                                        }}
                                    >
                                        123 <Text className="time-suffix">mins</Text>
                                    </Trans>
                                }
                                expMinsLabel={t('packagePicker.labels.experienceTime')}
                                perPersonValue={
                                    <Currency
                                        value={packageTierPrice!.price.listPrice}
                                        currency={packageTierPrice!.price.currencyCode}
                                        className="value"
                                        locale={locale}
                                    />
                                }
                                perPersonLabel={perPersonLabel}
                                ageValue={minimumAge}
                                ageLabel={t('packagePicker.labels.age')}
                                gameInfo={false}
                            />
                        </Col>
                        <Col span="auto" style={{ marginLeft: 'auto' }}>
                            <Row justify="end" align="middle">
                                <Col span="auto" className="package-toggle-container">
                                    <GamePackageDetailsButton
                                        label={t('packagePicker.labels.packageDetails')}
                                        onClick={togglePanel}
                                        panelOpen={panelOpen}
                                    />
                                </Col>
                                <Col span="auto" className="add-button-container">
                                    <GamePackageAddButton
                                        label={isSelected ? t('packagePicker.labels.added') : t('packagePicker.labels.add')}
                                        onClick={sessionSelected}
                                        isSelected={isSelected}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    <GamePackageScarcity
                        recentTicketsSold={session.package.recentTicketsSold} />
                    <Collapse
                        className="zl-hidden-collapse"
                        bordered={false}
                        expandIcon={({ isActive }) => <React.Fragment />}
                        activeKey={activeKey}
                    >
                        <Panel header="" key={`${session.sessionId}-${session.packageId}`} className="package-desc">
                            <PackageDescription description={languageData?.description} />
                        </Panel>
                    </Collapse>
                    <PackagePrivacy
                        privacyTypeId={session.package.privacyTypeId}
                        locale={locale}
                        currency={packageTierPrice!.price.currencyCode}
                        upgradeCost={privateBookingCost}
                        upgradeChecked={props.selectedPrivateUpgrade}
                        onCheckChanged={(e) => {
                            if(props.onUpgradeCheckChanged)
                                props.onUpgradeCheckChanged(e)
                        }} />
                </Col>
            </Row>
        );

    return (
        <Row justify="start" align="top" className="zl-session" key={session.sessionName}>
            <Col span={24}>
                <Row justify="start" align="bottom">
                    <Col span={24} className="session-header">
                        <GamePackageImage
                            session={session}
                            imageUrl={headerImage}
                            fallbackImage={noImage}
                            packageName={languageData?.name ?? session.package.alias}
                            modalOkText={t('packagePicker.labels.close')}
                            videoUrl={session.package.videoUrl}
                        />
                        <GamePackageHeader
                            tagline={t('packagePicker.labels.virtualRealityExperience')}
                            packageName={languageData?.name ?? session.package.alias}
                            accessCode={session.accessCode}
                        />
                    </Col>
                </Row>
                <Row justify="start" align="middle" className="mobile-details-row">
                    <Col span={24} className="package-details">
                        <GamePackageInfo
                            playersValue={t('packagePicker.labels.playersMinMax', {
                                min: `${numberHelpers.toNumber(locale, session.minimumSlots)}`,
                                max: `${numberHelpers.toNumber(locale, session.maximumSlots)}`,
                            })}
                            playersLabel={t('packagePicker.labels.players')}
                            expMinsValue={
                                <Trans
                                    i18nKey="packagePicker.labels.minutes"
                                    values={{
                                        minutes: `${numberHelpers.toNumber(
                                            locale,
                                            session.package.experienceDurationMinutes
                                        )}`,
                                    }}
                                >
                                    123 <Text className="time-suffix">mins</Text>
                                </Trans>
                            }
                            expMinsLabel={t('packagePicker.labels.experienceTime')}
                            perPersonValue={
                                <Currency
                                    value={packageTierPrice!.price.listPrice}
                                    currency={packageTierPrice!.price.currencyCode}
                                    className="value"
                                    locale={locale}
                                />
                            }
                            perPersonLabel={t('packagePicker.labels.perPerson')}
                            ageValue={minimumAge}
                            ageLabel={t('packagePicker.labels.age')}
                            gameInfo={false}
                        />
                    </Col>
                </Row>
                <Row justify="start" align="middle" className="mobile-details-row">
                    <Col span={16} className="package-toggle-container">
                        <GamePackageDetailsButton
                            label={t('packagePicker.labels.packageDetails')}
                            onClick={togglePanel}
                            panelOpen={panelOpen}
                        />
                    </Col>
                    <Col span={8} className="add-button-container">
                        <GamePackageAddButton
                            label={isSelected ? t('packagePicker.labels.added') : t('packagePicker.labels.add')}
                            onClick={sessionSelected}
                            isSelected={isSelected}
                        />
                    </Col>
                </Row>
            </Col>

            <Col span={24}>
                <GamePackageScarcity
                    recentTicketsSold={session.package.recentTicketsSold} />
                <Collapse
                    className="zl-hidden-collapse"
                    bordered={false}
                    expandIcon={({ isActive }) => <React.Fragment />}
                    activeKey={activeKey}>
                    <Panel header="" key={`${session.sessionId}-${session.packageId}`} className="package-desc">
                        <PackageDescription description={languageData?.description} />
                    </Panel>
                </Collapse>
                <PackagePrivacy
                    privacyTypeId={session.package.privacyTypeId}
                    locale={locale}
                    currency={packageTierPrice!.price.currencyCode}
                    upgradeCost={privateBookingCost}
                    upgradeChecked={props.selectedPrivateUpgrade}
                    onCheckChanged={(e) => {
                        if(props.onUpgradeCheckChanged)
                            props.onUpgradeCheckChanged(e)
                    }} />
            </Col>
        </Row>
    );
};

export default React.memo(GamePackage);
