import i18n from 'i18next';
import Package from '../package.json';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { languageList } from './helpers/lang';

const languageCodes = languageList.map((language: any) => `${language.code}`);

i18n.use(Backend) //loads json localisation files https://github.com/i18next/i18next-http-backend
    .use(initReactI18next) //react things
    .init({
        preload: false,
        fallbackLng: 'en-US',
        whitelist: [...languageCodes, 'en'],
        nonExplicitWhitelist: false,
        simplifyPluralSuffix: false,
        debug: false,
        load: 'currentOnly',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            backends: [
                LocalStorageBackend, // primary
                HttpApi, // fallback
            ],
            backendOptions: [
                {
                    // prefix for stored languages
                    prefix: 'zl_t_',

                    // expiration (one day)
                    expirationTime: 1 * 24 * 60 * 60 * 1000,

                    // Version applied to all languages, can be overriden using the option `versions`
                    defaultVersion: `${Package.version}`,

                    // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
                    store: window.localStorage,
                },
                {
                    loadPath: '/locale/{{lng}}/{{ns}}.json', // xhr load path for my own fallback
                    queryStringParams: { v: `${Package.version}` }
                },
            ],
        },
    });

export default i18n;
