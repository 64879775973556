import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';

export interface ISummaryItemProps {
    title: PropTypes.ReactNodeLike;
    value?: PropTypes.ReactNodeLike;
    fullWidth?: boolean;
}

const SummaryItem: React.FunctionComponent<ISummaryItemProps> = (props) => {
    const { value, title, fullWidth } = props;
    if (value === undefined || value === '') return <React.Fragment></React.Fragment>;

    if (fullWidth)
        return (
            <Row justify="start" align="top">
                <Col span={24}>
                    <Text strong>{title}</Text>
                </Col>
                <Col span={24}>
                    <Paragraph>{value}</Paragraph>
                </Col>
            </Row>
        );

    return (
        <Row justify="start" align="top">
            <Col span={10}>
                <Text strong>{title}</Text>
            </Col>
            <Col span={14}>
                <Paragraph>{value}</Paragraph>
            </Col>
        </Row>
    );
};

export default React.memo(SummaryItem);
