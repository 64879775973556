import IBooking from '../interfaces/IBooking';
import { ICharge } from '../interfaces/ICharge';
import ICheckoutChargeResponse from '../interfaces/ICheckoutChargeResponse';
import IPaymentGateway from '../interfaces/IPaymentGateway';
import IStripeChargeResponse from '../interfaces/IStripeChargeResponse';
import errorHelpers from '../helpers/errors';
import { PaymentIntent, PaymentMethod } from '@stripe/stripe-js';

class Payments {
    static GetPaymentGateways = (siteId: number): Promise<Array<IPaymentGateway>> => {
        return fetch(`payments/gateways/${siteId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<Array<IPaymentGateway>>);
    };

    static StripePayment = (
        siteId: number,
        paymentId: number,
        charge: ICharge,
        name: string | null,
        emailAddress: string,
        bookingId: number | null
    
    ): Promise<IStripeChargeResponse> => {
        const paymentRequest = {
            emailAddress: emailAddress,
            name: name,
            charge: charge,
            bookingId: bookingId
        };

        return fetch(`payments/${siteId}/${paymentId}/pay/stripe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentRequest),
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IStripeChargeResponse>);
    };

    static StripeConfirmation = (
        paymentIntent: PaymentIntent,
        siteId: number,
        paymentId: number,
    ): Promise<IStripeChargeResponse> => {
        const paymentRequest = {
            paymentIntentId: paymentIntent.id
        };

        return fetch(`payments/${siteId}/${paymentId}/pay/stripe/confirm`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentRequest),
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IStripeChargeResponse>);
    };

    static StripePaymentStatus = (
        paymentIntent: string,
        siteId: number,
        paymentId: number,
    ): Promise<IStripeChargeResponse> => {
        return fetch(`payments/${siteId}/${paymentId}/pay/stripe/${paymentIntent}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IStripeChargeResponse>);
    };

    static CheckoutPayment = (
        charge: ICharge,
        token: string,
        emailAddress: string,
        name: string,
        phoneNumber: string,
        siteId: number,
        paymentId: number,
        adBlockDetected: boolean
    ): Promise<ICheckoutChargeResponse> => {
        const paymentRequest = {
            cardToken: token,
            emailAddress: emailAddress,
            charge: charge,
            name: name,
            phoneNumber: phoneNumber,
            adBlockDetected: adBlockDetected,
            newPaymentSystem: true,
    };

        return fetch(`payments/${siteId}/${paymentId}/pay/checkout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentRequest),
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<ICheckoutChargeResponse>);
    };
}

export default Payments;
