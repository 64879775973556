import { IGoogleTagManagerEvent } from '../store';
import IBooking from '../interfaces/IBooking';
import { ICharge } from '../interfaces/ICharge';
import ISession from '../interfaces/ISession';
import PackageTierPrice from '../interfaces/IPackageTierPrice';
import SessionTime from '../interfaces/ISessionTime';
import Site from '../interfaces/ISite';
import Experience from '../interfaces/IExperience';
import TagManager from 'react-gtm-module';
import { IDiscountCode } from '../interfaces/IDiscountCode';
import { IAttributionState } from '../store/Attribution';
import regionConverter from '../helpers/regionConverter';
import { IGiftVoucher } from '../interfaces/GiftVouchers/IGiftVoucher';


class GoogleTagManager {
    public static init(googleTagManagerKey: string, dataLayer: string = 'dataLayer') {
        
        //Set up the new gtm scripts using the tagManager plugin that doesn't clean up after itself
        //allowing the tags to pile up on the page does not cause any issues
        const tagManagerArgs = {
            gtmId: googleTagManagerKey,
            dataLayerName: dataLayer
        };
        console.log("Initialize gtm for container Key: '" + googleTagManagerKey + "', and data layer: '" + dataLayer + "'");
        TagManager.initialize(tagManagerArgs);
    }

    public static sendToDataLayer(dataLayer: any, siteDataLayer?: string) {
        const baseDataLayerArgs = {
            dataLayer: dataLayer,
            dataLayerName: 'dataLayer'
        }
        TagManager.dataLayer(baseDataLayerArgs);

        if(siteDataLayer) {
            const siteDataLayerArgs = {
                dataLayer: dataLayer,
                dataLayerName: siteDataLayer
            }
            TagManager.dataLayer(siteDataLayerArgs);
        }
    }

    public static sendToSiteDataLayer(dataLayer: any, siteDataLayer: string) {
        const siteDataLayerArgs = {
            dataLayer: dataLayer,
            dataLayerName: siteDataLayer
        }
        TagManager.dataLayer(siteDataLayerArgs);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static addAttributionToDataLayerEvent(dataLayer: any, attribution: IAttributionState): any {
        dataLayer.attribution = {
            utm_campaign: attribution.utm_campaign || undefined,
            utm_content: attribution.utm_content || undefined,
            utm_id: attribution.utm_Id || undefined,
            utm_medium: attribution.utm_medium || undefined,
            utm_source: attribution.utm_source || undefined,
            utm_term: attribution.utm_term || undefined,

            internal_source: attribution.internal_source || undefined,
            internal_sourcePage: attribution.internal_sourcePage || undefined,
            internal_componentType: attribution.internal_componentType || undefined,
            internal_componentInternalName: attribution.internal_componentInternalName || undefined,
            internal_componentTitle: attribution.internal_componentTitle || undefined,
            internal_buttonText: attribution.internal_buttonText || undefined,
        };

        return dataLayer;
    }

    public static pageView(pathname: string, title: string, attribution: IAttributionState, siteDataLayer?: string) {
        let dataLayer = {
            category: 'Booking Engine',
            event: 'pageview',
            page: {
                url: pathname,
                title: title
            },
            eventProps: {
                value: pathname,
                title: title
            }
        };

        dataLayer = GoogleTagManager.addAttributionToDataLayerEvent(dataLayer, attribution);

        GoogleTagManager.sendToDataLayer(dataLayer, siteDataLayer);
    }

    public static modalView(pathname: string, trackers?: string[]) {
        // if (trackers) ReactGA.modalview(pathname, trackers);
        // else ReactGA.modalview(pathname);
    }

    public static sendEnhancedDataLayerEvent(
        request: IGoogleTagManagerEvent,
        purchaseState: object,
        cart: object | undefined,
        attribution: IAttributionState,
        siteDataLayer?: string
    ) {
        const dataLayerReset = {
            event: undefined,
            eventProps: undefined,
        };

        let enhancedDataLayer = {
            category: 'Booking Engine',
            event: request.event,
            eventProps: request.eventProps,
            state: purchaseState,
            cart: cart
        };

        enhancedDataLayer = GoogleTagManager.addAttributionToDataLayerEvent(enhancedDataLayer, attribution);

        GoogleTagManager.sendToDataLayer(enhancedDataLayer, siteDataLayer);
        GoogleTagManager.sendToDataLayer(dataLayerReset, siteDataLayer);
    }

    public static event(
        request: Array<IGoogleTagManagerEvent> | IGoogleTagManagerEvent,
        purchaseState: object,
        cart: object | undefined,
        attribution: IAttributionState,
        siteDataLayer?: string
    ) {
        if (Array.isArray(request)) {
            request.forEach((event) => {
                GoogleTagManager.sendEnhancedDataLayerEvent(event, purchaseState, cart, attribution, siteDataLayer);
            });
        } else {
            GoogleTagManager.sendEnhancedDataLayerEvent(request, purchaseState, cart, attribution, siteDataLayer);
        }
    }

    // Bookings
    public static addToCart(
        totalCost: number,
        ticketCost: number,
        totalTax: number,
        currency: string,
        packageSku: string,
        packageName: string,
        siteCode: string,
        bookingSlots: number,
        discountCode: string,
        discount: number,
        attribution: IAttributionState,
        siteDataLayer?: string
    ) {
        let dataLayer = {
                event: 'Added Package to Cart',
                eventModel: {
                    'value': totalCost,
                    'currency': currency,
                    'coupon': discountCode,
                    'tax': totalTax,
                    'affiliation': siteCode,
                    'items': [{
                        'item_id': packageSku,
                        'item_name': packageName,
                        'affiliation': siteCode,
                        'coupon': discountCode,
                        'currency': currency,
                        'discount': discount,
                        'item_brand': 'Zero Latency',
                        'item_category': 'Tickets',
                        'quantity': bookingSlots,
                        'price': ticketCost
                    }]
                }
            };

        dataLayer = GoogleTagManager.addAttributionToDataLayerEvent(dataLayer, attribution);

        GoogleTagManager.sendToDataLayer(dataLayer, siteDataLayer);
    }

    
    public static checkoutComplete(
        bookingId: string,
        attribution: IAttributionState,
        siteDataLayer?: string
    ) {
        let dataLayer = {
            event: 'Checkout Complete',
            eventModel: {
                'booking_id': bookingId
            }
        };

        dataLayer = GoogleTagManager.addAttributionToDataLayerEvent(dataLayer, attribution);

        GoogleTagManager.sendToDataLayer(dataLayer, siteDataLayer);
    }

    public static addTransaction(
        bookingId: string,
        totalCost: number,
        ticketCost: number,
        totalTax: number,
        currency: string,
        packageSku: string,
        packageName: string,
        siteCode: string,
        bookingSlots: number,
        attribution: IAttributionState,
        siteDataLayer: string
    ) {
        let dataLayer = {
            'transactionId': bookingId,
            'transactionTotal': totalCost,
            'transactionTax': totalTax,
            'transactionCurrency': currency,
            'transactionProducts': [{
                'sku': packageSku,
                'name': packageName,
                'category': siteCode,
                'price': ticketCost,
                'quantity': bookingSlots 
            }]
        };

        dataLayer = GoogleTagManager.addAttributionToDataLayerEvent(dataLayer, attribution);
        GoogleTagManager.sendToSiteDataLayer(dataLayer, siteDataLayer);
    }

    public static confirmConversion(
        bookingId: string,
        totalCost: number,
        ticketCost: number,
        totalTax: number,
        currency: string,
        packageSku: string,
        packageName: string,
        siteCode: string,
        bookingSlots: number,
        discountCode: string,
        discount: number,
        attribution: IAttributionState,
        siteDataLayer: string
    ) {
        let dataLayer = {
            event: 'conversion',
            eventModel: {
                'value': totalCost,
                'currency': currency,
                'transaction_id': bookingId,
                'coupon': discountCode,
                'tax': totalTax,
                'affiliation': siteCode,
                'items': [{
                    'item_id': packageSku,
                    'item_name': packageName,
                    'affiliation': siteCode,
                    'coupon': discountCode,
                    'currency': currency,
                    'discount': discount,
                    'item_brand': 'Zero Latency',
                    'item_category': 'Tickets',
                    'quantity': bookingSlots,
                    'price': ticketCost
                }]
            }
        };

        dataLayer = GoogleTagManager.addAttributionToDataLayerEvent(dataLayer, attribution);
        GoogleTagManager.sendToSiteDataLayer(dataLayer, siteDataLayer);
    }

    // Gift Vouchers
    public static addGiftVoucherToCart(
        amount: number,
        currency: string,
        totalTax: number,
        siteCode: string,
        toRecipient: boolean,
        recipientEmail: string,
        recipientName: string,
        attribution: IAttributionState,
        siteDataLayer: string
    ) {
        let dataLayer = {
            event: 'Added Gift Voucher to Cart',
            eventModel: {
                'value': amount,
                'currency': currency,
                'tax': totalTax,
                'affiliation': siteCode,
                'items': [{
                    'to_recipient': toRecipient,
                    'recipient_email': recipientEmail,
                    'recipient_name': recipientName,
                    'affiliation': siteCode,
                    'currency': currency,
                    'item_brand': 'Zero Latency',
                    'item_category': 'Gift Voucher',
                    'quantity': 1,
                    'price': amount
                }]
            }
        };

        dataLayer = GoogleTagManager.addAttributionToDataLayerEvent(dataLayer, attribution);
        GoogleTagManager.sendToDataLayer(dataLayer, siteDataLayer);
    }

    public static addGiftVoucherTransaction(
        giftVoucherId: string,
        totalCost: number,
        totalTax: number,
        currency: string,
        siteCode: string,
        attribution: IAttributionState,
        siteDataLayer: string
    ) {
        let dataLayer = {
            'transactionId': giftVoucherId,
            'transactionTotal': totalCost,
            'transactionTax': totalTax,
            'transactionCurrency': currency,
            'transactionProducts': [{
                'category': siteCode,
                'quantity': 1
            }]
        };

        dataLayer = GoogleTagManager.addAttributionToDataLayerEvent(dataLayer, attribution);
        GoogleTagManager.sendToSiteDataLayer(dataLayer, siteDataLayer);
    }

    public static confirmGiftVoucherConversion(
        giftVoucherId: string,
        totalCost: number,
        totalTax: number,
        currency: string,
        siteCode: string,
        toRecipient: boolean,
        recipientEmail: string,
        recipientName: string,
        attribution: IAttributionState,
        siteDataLayer: string
    ) {
        let dataLayer = {
            event: 'conversion',
            eventModel: {
                'value': totalCost,
                'currency': currency,
                'transaction_id': giftVoucherId,
                'tax': totalTax,
                'affiliation': siteCode,
                'items': [{
                    'to_recipient': toRecipient,
                    'recipient_email': recipientEmail,
                    'recipient_name': recipientName,
                    'affiliation': siteCode,
                    'currency': currency,
                    'item_brand': 'Zero Latency',
                    'item_category': 'Gift Voucher',
                    'quantity': 1,
                    'coupon': undefined,
                    'discount': 0.00,
                    'price': totalCost
                }]
            }
        };

        dataLayer = GoogleTagManager.addAttributionToDataLayerEvent(dataLayer, attribution);
        GoogleTagManager.sendToSiteDataLayer(dataLayer, siteDataLayer);
    }
}

export class GoogleTagManagerEvents {

    public static PurchaseTypeSelected(purchaseType: string): Array<IGoogleTagManagerEvent> {
        return [{
            category: 'tickets',
            action: 'purchase type selected',
            label: 'purchase type',
            event: 'Selected Purchase Type',
            eventProps: {
                type: 'Button',
                value: purchaseType
            }
        }];
    }

    // Bookings
    public static ReserveBooking(
        charge: ICharge
    ): Array<IGoogleTagManagerEvent> {
        return [
            {
                category: 'tickets',
                action: 'proceed to checkout',
                label: 'booking',
                value: charge.total,
                event: 'Proceed to Checkout',
                eventProps: {
                    type: 'Button',
                    value: charge.total
                }
            },
        ];
    }

    public static ConfirmBooking(charge: ICharge, session: ISession, booking: IBooking, siteCode: string, appliedCodes: Array<IDiscountCode>, gtmKey?: string): Array<IGoogleTagManagerEvent> {
       
        const transactionId = `${booking.bookingId}`;

        return [{ category: '', action: '', label: '', 
            event: 'Checkout Complete', 
            eventProps: {
                type: 'Browser',
                value: transactionId
            } }];
    }

    public static BookingExpired(bookingRef: string, bookingId: number): Array<IGoogleTagManagerEvent> {
        return [{ category: 'checkout', action: 'booking expired', label: bookingRef, value: bookingId, 
        event: 'Booking Expired', 
        eventProps: {
            type: 'Browser',
            value: bookingId
        } }];
    }

    public static RecordEmailAddress(): Array<IGoogleTagManagerEvent> {
        return [{ category: 'checkout', action: 'contact details', label: 'input email address', 
        event: 'Input Email Address', 
        eventProps: {
            type: 'Input'
        } }];
    }

    public static RecordFullName(): Array<IGoogleTagManagerEvent> {
        return [{ category: 'checkout', action: 'contact details', label: 'input full name', 
        event: 'Input Full Name', 
        eventProps: {
            type: 'Input'
        } }];
    }

    public static RecordPhoneNumber(): Array<IGoogleTagManagerEvent> {
        return [{ category: 'checkout', action: 'contact details', label: 'input phone number', 
        event: 'Input Phone Number', 
        eventProps: {
            type: 'Input'
        } }];
    }

    public static AppliedDiscountCode(discountCode: string): Array<IGoogleTagManagerEvent> {
        return [{ category: 'checkout', action: 'applied discount code', label: discountCode, 
        event: 'Applied Discount Code', 
        eventProps: {
            type: 'Input',
            value: discountCode
        } }];
    }

    public static InvalidDiscountCode(discountCode: string): Array<IGoogleTagManagerEvent> {
        return [{ category: 'checkout', action: 'invalid discount code', label: discountCode, 
        event: 'Invalid Discount Code', 
        eventProps: {
            type: 'Input',
            value: discountCode
        } }];
    }

    public static PlayerSearchReceived(playerFound: boolean): Array<IGoogleTagManagerEvent> {
        if (playerFound) return [{ category: 'checkout', action: 'player search', label: 'returning player', 
        event: 'Player Search', 
        eventProps: {
            type: 'Browser',
            value: playerFound
        } }];
        else return [];
    }

    public static PlayersSelected(players: number): Array<IGoogleTagManagerEvent> {
        return [{ category: 'tickets', action: 'selected players', label: 'players', value: players, 
        event: 'Select Players', 
        eventProps: {
            type: 'Button',
            value: players
        }}];
    }

    public static PrivateEventTypeSelected(privateEventType: number): Array<IGoogleTagManagerEvent> {
        return [{ category: 'tickets', action: 'selected private event type', label: 'private event type', value: privateEventType, 
        event: 'Select Private Event Type', 
        eventProps: {
            type: 'Button',
            value: privateEventType
        }}];
    }

    public static PrivateUpgradeSelected(upgradeChecked: boolean): Array<IGoogleTagManagerEvent> {
        return [{ category: 'tickets', action: 'selected private upgrade', label: 'private upgrade', value: upgradeChecked ? 1 : 0, 
        event: 'Select Private Upgrade', 
        eventProps: {
            type: 'Button',
            value: upgradeChecked
        }}];
    }

    public static SessionTimeSelected(time: SessionTime): Array<IGoogleTagManagerEvent> {
        return [{ category: 'tickets', action: 'selected time', label: time.time, 
        event: 'Select Time', 
        eventProps: {
            type: 'Button',
            value: time.time,
            slots_remaining: time.slotsRemaining
        }}];
    }

    public static SessionDateSelected(date: string): Array<IGoogleTagManagerEvent> {
        return [{ category: 'tickets', action: 'selected date', label: date, 
        event: 'Select Date', 
        eventProps: {
            type: 'Button',
            value: date
        } }];
    }

    public static SessionPackageSelected(session: ISession): Array<IGoogleTagManagerEvent> {
        return [
            {
                category: 'tickets',
                action: 'selected session',
                label: session.package.alias,
                value: session.package.packageId,

                event: 'Package Added To Cart', 
                eventProps: {
                    type: 'Button',
                    session_time: session.startTime,
                    package: session.package.header,
                    package_id: session.package.packageId,
                    package_name: session.package.alias
                }
            },
        ];
    }

    public static LocationRequested(): Array<IGoogleTagManagerEvent> {
        return [{ category: 'tickets', action: 'location requested', label: 'browser', 
        event: 'Location Requested', 
        eventProps: {
            type: 'Button'
        } }];
    }

    public static SiteSelected(site: Site, nearest: boolean): Array<IGoogleTagManagerEvent> {

        const region = regionConverter.regionIdToString(site.regionId);

        return [{ category: 'tickets', action: 'select venue', label: nearest ? 'browser' : 'dropdown', 
            event: 'Select Venue', 
            eventProps: {
                type: nearest ? 'Browser' : 'Dropdown',
                value: site.name,
                venue_id: site.siteId,
                venue_code: site.code,
                nearest: nearest,
                country: site.country,
                region: region,
            }
        }];
    }

    public static GameSelected(game: Experience | undefined): Array<IGoogleTagManagerEvent> {
        return [{ category: 'tickets', action: 'select game', label: game?.name ?? 'All Games', 
        event: 'Select Game', 
        eventProps: {
            type: 'Dropdown',
            value: game?.name,
            alias: game?.alias
        } }];
    }

    public static ViewedGroupEnquiry(): Array<IGoogleTagManagerEvent> {
        return [{ category: 'tickets', action: 'viewed group enquiry', label: '', 
        event: 'Group Enquiry', 
        eventProps: {
            type: 'Button'
        } }];
    }
    public static SentGroupEventEnquiry(): Array<IGoogleTagManagerEvent> {
        return [{ category: 'tickets', action: 'sent group enquiry', label: '', 
        event: 'Group Enquiry', 
        eventProps: {
            type: 'Button'
        } }];
    }

    public static ViewedPackageDetails(session: ISession, siteCode: string, ticketCount: number, appliedCodes: Array<IDiscountCode>): Array<IGoogleTagManagerEvent> {

        const discount = appliedCodes[0] != null ? ((appliedCodes[0]?.amount * 100) / ticketCount) / 100 : 0;

        const priceTierTypeId = session?.priceTierTypeId;
        const packageTierPrice = session?.package.packageTierPrices.find(
            (ptp: PackageTierPrice) => ptp.priceTierType.priceTierTypeId === priceTierTypeId
        );

        const pricing = packageTierPrice?.price;
        const cost = packageTierPrice?.price.cost;
        const currencyCode = pricing?.currencyCode ?? '' as string;

        return [
            { category: 'tickets', action: 'viewed package details', label: session.package.alias, value: session.packageId, 
            event: 'Package Details', 
            eventProps: {
                type: 'Button',
                session_time: session.startTime,
                package: session.package.header,
                package_name: session.package.alias,
                items: [{
                    item_id: session.package.header,
                    item_name: session.package.alias,
                    affiliation: siteCode,
                    currency: currencyCode,
                    discount: discount,
                    item_brand: 'Zero Latency',
                    item_category: 'Tickets',
                    quantity: ticketCount,
                    price: cost,
                    }]
                }
            } 
        ];
    }

    public static ViewedPackageVideo(session: ISession, siteCode: string, ticketCount: number, appliedCodes: Array<IDiscountCode>): Array<IGoogleTagManagerEvent> {
        const discount = appliedCodes[0] != null ? ((appliedCodes[0]?.amount * 100) / ticketCount) / 100 : 0;

        const priceTierTypeId = session?.priceTierTypeId;
        const packageTierPrice = session?.package.packageTierPrices.find(
            (ptp: PackageTierPrice) => ptp.priceTierType.priceTierTypeId === priceTierTypeId
        );

        const pricing = packageTierPrice?.price;
        const cost = packageTierPrice?.price.cost;
        const currencyCode = pricing?.currencyCode ?? '' as string;

        return [{
                category: 'tickets', action: 'viewed package video', label: session.package.alias, value: session.packageId,
                event: 'Package Video',
                eventProps: {
                    type: 'Video',
                    session_time: session.startTime,
                    package: session.package.header,
                    package_name: session.package.alias,
                    items: [{
                        item_id: session.package.header,
                        item_name: session.package.alias,
                        affiliation: siteCode,
                        currency: currencyCode,
                        discount: discount,
                        item_brand: 'Zero Latency',
                        item_category: 'Tickets',
                        quantity: ticketCount,
                        price: cost,
                    }]
                }
            }
        ];
    }

    public static ClickedOutboundLink(outboundUrl: string): Array<IGoogleTagManagerEvent> {
        return [{ category: 'tickets', action: 'clicked outbound link', label: outboundUrl, 
        event: 'Outbound Link', 
        eventProps: {
            type: 'Button',
            value: outboundUrl
        } }];
    }

    public static ClickedReadFAQ(): Array<IGoogleTagManagerEvent> {
        return [{ category: 'checkout', action: 'read FAQ', label: '', 
        event: 'FAQ', 
        eventProps: {
            type: 'Button'
        } }];
    }

    public static ClickedReadTOS(): Array<IGoogleTagManagerEvent> {
        return [{ category: 'checkout', action: 'read terms of service', label: '', 
        event: 'Terms of Service', 
        eventProps: {
            type: 'Button'
        } }];
    }

    public static ClickedReadPrivacyPolicy(): Array<IGoogleTagManagerEvent> {
        return [{ category: 'checkout', action: 'read privacy policy', label: '', 
        event: 'Privacy Policy', 
        eventProps: {
            type: 'Button'
        } }];
    }

    public static ModifiedBooking(booking: IBooking): Array<IGoogleTagManagerEvent> {
        return [{ category: 'checkout', action: 'modified booking', label: 'booking id', value: booking.bookingId, 
        event: 'Modified Booking', 
        eventProps: {
            type: 'Button',
            value: booking.bookingId
        } }];
    }

    public static ValidPaymentDetails(gateway: string): Array<IGoogleTagManagerEvent> {
        return [{ category: 'checkout', action: 'valid payment details', label: gateway, 
        event: 'Valid Payment Details', 
        eventProps: {
            type: 'Input',
            value: gateway
        } }];
    }

    public static PaymentFailed(gateway: string): Array<IGoogleTagManagerEvent> {
        return [{ category: 'checkout', action: 'payment failed', label: gateway, 
        event: 'Payment Failed', 
        eventProps: {
            type: 'Input',
            value: gateway
        } }];
    }

    public static CopiedBookingShareLink(booking: IBooking): Array<IGoogleTagManagerEvent> {
        return [{ category: 'complete', action: 'share details', label: 'booking id', value: booking.bookingId, 
        event: 'Share Details', 
        eventProps: {
            type: 'Button',
            value: booking.bookingId
        } }];
    }

    public static ClosedSurvey(): Array<IGoogleTagManagerEvent> {
        return [{ category: 'complete', action: 'closed survey', label: '', 
        event: 'Closed Survey', 
        eventProps: {
            type: 'Button'
        } }];
    }

    public static SubscribedEmailToMarketing(isChecked: boolean): Array<IGoogleTagManagerEvent> {
        return [{
            category: 'checkout', action: 'marketing email subscribe', label: '', 
        event: 'Marketing Email Subscribe', 
        eventProps: {
            value: isChecked
        } }];
    }

    public static SubscribedPhoneToMarketing(isChecked: boolean): Array<IGoogleTagManagerEvent> {
        return [{
            category: 'checkout', action: 'marketing phone subscribe', label: '', 
            event: 'Marketing Phone Subscribe',
            eventProps: {
                value: isChecked
            }
        }];
    }

    // Gift Vouchers

    public static CustomGiftVoucherAmountInput(amount: number): Array<IGoogleTagManagerEvent> {
        return [{
            category: 'tickets',
            action: 'gift voucher amount input',
            label: 'gift voucher',          
            event: 'Custom Gift Voucher Amount input',
            eventProps: {
                type: 'Input',
                value: amount,
            }
        }];
    }

    public static FixedGiftVoucherAmountSelected(amount: number): Array<IGoogleTagManagerEvent> {
        return [{
            category: 'tickets',
            action: 'gift voucher amount selected',
            label: 'gift voucher',
            event: 'Fixed Gift Voucher Amount Selected',
            eventProps: {
                type: 'Button',
                value: amount,
            }
        }];
    }

    public static GiftVoucherRecipientSelected(toRecipient: boolean): Array<IGoogleTagManagerEvent> {
        return [{
            category: 'tickets',
            action: 'gift voucher recipient selected',
            label: 'gift voucher',
            event: 'Gift Voucher Recipient Selected',
            eventProps: {
                type: 'Button',
                toRecipient: toRecipient
            }
        }];
    }

    public static RecordRecipientEmailAddress(): Array<IGoogleTagManagerEvent> {
        return [{
            category: 'tickets', action: 'recipient contact details', label: 'input recipient email address',
            event: 'Input Recipient Email Address',
            eventProps: {
                type: 'Input'
            }
        }];
    }

    public static RecordRecipientFullName(): Array<IGoogleTagManagerEvent> {
        return [{
            category: 'tickets', action: 'recipient contact details', label: 'input recipient full name',
            event: 'Input Recipient Full Name',
            eventProps: {
                type: 'Input'
            }
        }];
    }

    public static RecordRecipientMessage(): Array<IGoogleTagManagerEvent> {
        return [{
            category: 'tickets', action: 'recipient contact details', label: 'input recipient message',
            event: 'Input Recipient Message',
            eventProps: {
                type: 'Input'
            }
        }];
    }

    public static ReserveGiftVoucher(
        charge: ICharge
    ): Array<IGoogleTagManagerEvent> {
        return [
            {
                category: 'tickets',
                action: 'proceed to checkout',
                label: 'gift voucher',
                value: charge.total,
                event: 'Proceed to Checkout',
                eventProps: {
                    type: 'Button',
                    value: charge.total
                }
            },
        ];
    }

    public static ConfirmGiftVoucher(charge: ICharge, giftVoucher: IGiftVoucher, siteCode: string, gtmKey?: string): Array<IGoogleTagManagerEvent> {

        const transactionId = `${giftVoucher.giftVoucherId}`;

        return [{
            category: '', action: '', label: '',
            event: 'Checkout Complete',
            eventProps: {
                type: 'Browser',
                value: transactionId
            }
        }];
    }

    public static ModifiedGiftVoucher(giftVoucher: IGiftVoucher): Array<IGoogleTagManagerEvent> {
        return [{
            category: 'checkout', action: 'modified gift voucher', label: 'gift voucher id', value: giftVoucher.giftVoucherId,
            event: 'Modified Gift Voucher',
            eventProps: {
                type: 'Button',
                value: giftVoucher.giftVoucherId
            }
        }];
    }

    public static RecordPriceSet(
        venue: string,
        priceCode: string,
        source: string
    ): Array<IGoogleTagManagerEvent> {
        return [
            {
                category: 'tickets',
                action: 'price code allocated',
                label: 'price code',
                event: 'Price Code Allocated',
                eventProps: {
                    type: 'Price Code',
                    venue: venue,
                    priceCode: priceCode,
                    source: source
                }
            },
        ];
    }

}

export default GoogleTagManager;
