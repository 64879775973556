import React from 'react';
import { CaretDownFilled } from '@ant-design/icons';
import ZLButton, { ButtonType } from '../zl-button/zl-button';
import Text from 'antd/lib/typography/Text';

export interface IGamePackageDetailsButtonProps {
    panelOpen: boolean;
    label: string;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const GamePackageDetailsButton: React.FunctionComponent<IGamePackageDetailsButtonProps> = (props) => {
    return (
        <ZLButton type={ButtonType.Link} onClick={props.onClick}>
            <Text>{props.label}</Text>
            <CaretDownFilled className={`caret ${props.panelOpen ? 'active' : ''}`} />
        </ZLButton>
    );
};

export default React.memo(GamePackageDetailsButton);
