import { Action, Reducer } from 'redux';
import { IAppThunkAction } from '.';

export interface IAttributionState {
    //EG UTM stuff from a facebook add
    utm_Id?: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_medium?: string;
    utm_source?: string;
    utm_term?: string;

    //EG internal stuff passed from global website
    internal_source?: string;
    internal_sourcePage?: string;
    internal_componentType?: string;
    internal_componentInternalName?: string;
    internal_componentTitle?: string;
    internal_buttonText?: string;
}

export const UTM_LOADED: 'UTM_LOADED' = 'UTM_LOADED';
export const INTERNAL_ATTRIBUTION_LOADED: 'INTERNAL_ATTRIBUTION_LOADED' = 'INTERNAL_ATTRIBUTION_LOADED';

export interface IUtmLoadedAction extends Action {
    type: typeof UTM_LOADED;
    utmId?: string;
    campaign?: string;
    content?: string;
    medium?: string;
    source?: string;
    term?: string;
}

export interface IInternalAttributionLoadedAction extends Action {
    type: typeof INTERNAL_ATTRIBUTION_LOADED;
    source?: string;
    sourcePage?: string;
    componentType?: string;
    componentInternalName?: string;
    componentTitle?: string;
    buttonText?: string;
}

export type KnownAttributionActions = IUtmLoadedAction | IInternalAttributionLoadedAction;

export const actionCreators = {
    utmLoaded: (
        utmId: string | undefined,
        campaign: string | undefined,
        content: string | undefined,
        medium: string | undefined,
        source: string | undefined,
        term: string | undefined
    ): IAppThunkAction<KnownAttributionActions> => (dispatch: any, getState: any) => {
        dispatch({
            type: UTM_LOADED,
            utmId: utmId,
            campaign: campaign,
            content: content,
            medium: medium,
            source: source,
            term: term,
        } as IUtmLoadedAction);
    },
    internalAttributionLoaded: (
        source: string | undefined,
        sourcePage: string | undefined,
        componentType: string | undefined,
        componentInternalName: string | undefined,
        componentTitle: string | undefined,
        buttonText: string | undefined
    ): IAppThunkAction<KnownAttributionActions> => (dispatch: any, getState: any) => {
        dispatch({
            type: INTERNAL_ATTRIBUTION_LOADED,
            source: source,
            sourcePage: sourcePage,
            componentType: componentType,
            componentInternalName: componentInternalName,
            componentTitle: componentTitle,
            buttonText: buttonText,
        } as IInternalAttributionLoadedAction);
    },
};

const unloadedState: IAttributionState = {};

export const reducer: Reducer<IAttributionState> = (
    state: IAttributionState | undefined,
    incomingAction: Action
): IAttributionState => {
    if (state === undefined) return unloadedState;

    const action: KnownAttributionActions = incomingAction as KnownAttributionActions;
    switch (action.type) {
        case UTM_LOADED:
            return {
                ...state,
                utm_Id: action.utmId,
                utm_campaign: action.campaign,
                utm_content: action.content,
                utm_medium: action.medium,
                utm_source: action.source,
                utm_term: action.term,
            };
        case INTERNAL_ATTRIBUTION_LOADED:
            return {
                ...state,
                internal_source: action.source,
                internal_sourcePage: action.sourcePage,
                internal_componentType: action.componentType,
                internal_componentInternalName: action.componentInternalName,
                internal_componentTitle: action.componentTitle,
                internal_buttonText: action.buttonText,
            };
        default:
            return state;
    }
};
