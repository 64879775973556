import { CallHistoryMethodAction } from 'connected-react-router';
import { Action, Reducer } from 'redux';

import { IAnalyticsAction, IApplicationState, IAppThunkAction } from '.';
import { GoogleTagManagerEvents } from '../helpers/googleTagManager';
import IPaymentGateway, { PaymentGateway } from '../interfaces/IPaymentGateway';
import PaymentServices from '../service/payments';
import { push } from 'connected-react-router';
import { PurchaseTypes } from './PurchaseTypes';
import { actionCreators as UIActions } from '../store/UI';
import Logging from '../service/logging';
import {
    IDiscountCodeApplied, IClearBookingAction, IExpireBookingSuccessAction, IExpireBookingCompletedAction,
    DISCOUNT_CODE_APPLIED, EXPIRE_BOOKING_SUCCESS, EXPIRE_BOOKING_COMPLETE, CLEAR_BOOKING,
} from './Booking';
import {
    IUpdateGiftVoucherAmountSuccessAction, IClearGiftVoucherAction, ICancelGiftVoucherSuccessAction, ICancelGiftVoucherCompleteAction,
    UPDATE_GIFT_VOUCHER_AMOUNT_SUCCESS, CLEAR_GIFT_VOUCHER, CANCEL_GIFT_VOUCHER_SUCCESS, CANCEL_GIFT_VOUCHER_COMPLETE,
} from './GiftVoucher';
import BrazeEvents from '../helpers/braze';
// import { appInsights } from "../Logging/appInsights";

export interface IPaymentState {
    stripe: IStripePayment | undefined;
    paypal: IPayPalPayment | undefined;
    checkout: ICheckoutPayment | undefined;
    loading: boolean;
    backProcessing: boolean;
    clientSecret?: string;
}

export interface IPayment {
    apiKey?: string;
    paymentMethodType?: string;
}

export enum PaymentMethodTypes {
    Unknown = 1,
    CreditCard = 2,
    Credit = 3,
    Paypal = 4,
    FreedomPay = 6,
    GiftVoucher = 7,
    CodeVoucher = 8,
    Stripe = 9,
    Checkout = 10,
}

export type IStripePayment = IPayment;
export type IPayPalPayment = IPayment;
export type ICheckoutPayment = IPayment;

const PAYPAL_PAYMENT_REQUESTED: 'PAYPAL_PAYMENT_REQUESTED' = 'PAYPAL_PAYMENT_REQUESTED';
const PAYPAL_PAYMENT_COMPLETED: 'PAYPAL_PAYMENT_COMPLETED' = 'PAYPAL_PAYMENT_COMPLETED';
const PAYPAL_PAYMENT_FAILED: 'PAYPAL_PAYMENT_FAILED' = 'PAYPAL_PAYMENT_FAILED';
const PAYPAL_PAYMENT_CANCELLED: 'PAYPAL_PAYMENT_CANCELLED' = 'PAYPAL_PAYMENT_CANCELLED';
const PAYPAL_PAYMENT_SUCCESS: 'PAYPAL_PAYMENT_SUCCESS' = 'PAYPAL_PAYMENT_SUCCESS';

const CHECKOUT_PAYMENT_REQUESTED: 'CHECKOUT_PAYMENT_REQUESTED' = 'CHECKOUT_PAYMENT_REQUESTED';
const CHECKOUT_PAYMENT_COMPLETED: 'CHECKOUT_PAYMENT_COMPLETED' = 'CHECKOUT_PAYMENT_COMPLETED';
const CHECKOUT_PAYMENT_FAILED: 'CHECKOUT_PAYMENT_FAILED' = 'CHECKOUT_PAYMENT_FAILED';
const CHECKOUT_PAYMENT_SUCCESS: 'CHECKOUT_PAYMENT_SUCCESS' = 'CHECKOUT_PAYMENT_SUCCESS';

const STRIPE_PAYMENT_REQUESTED: 'STRIPE_PAYMENT_REQUESTED' = 'STRIPE_PAYMENT_REQUESTED';
const STRIPE_PAYMENT_COMPLETED: 'STRIPE_PAYMENT_COMPLETE' = 'STRIPE_PAYMENT_COMPLETE';
const STRIPE_PAYMENT_SUCCESS: 'STRIPE_PAYMENT_SUCCESS' = 'STRIPE_PAYMENT_SUCCESS';
const STRIPE_PAYMENT_FAILED: 'STRIPE_PAYMENT_FAILED' = 'STRIPE_PAYMENT_FAILED';
const STRIPE_PAYMENT_ACTION_REQUIRED: 'STRIPE_PAYMENT_ACTION_REQUIRED' = 'STRIPE_PAYMENT_ACTION_REQUIRED';

const GET_PAYMENT_GATEWAYS_REQUESTED: 'GET_PAYMENT_GATEWAYS_REQUESTED' = 'GET_PAYMENT_GATEWAYS_REQUESTED';
const GET_PAYMENT_GATEWAYS_SUCCESS: 'GET_PAYMENT_GATEWAYS_SUCCESS' = 'GET_PAYMENT_GATEWAYS_SUCCESS';
const GET_PAYMENT_GATEWAYS_COMPLETED: 'GET_PAYMENT_GATEWAYS_COMPLETED' = 'GET_PAYMENT_GATEWAYS_COMPLETED';
const GET_PAYMENT_GATEWAYS_FAILED: 'GET_PAYMENT_GATEWAYS_FAILED' = 'GET_PAYMENT_GATEWAYS_FAILED';

const BACK_PROCESSING_ADDED: 'BACK_PROCESSING_ADDED' = 'BACK_PROCESSING_ADDED';

const VALID_PAYMENT_DETAILS: 'VALID_PAYMENT_DETAILS' = 'VALID_PAYMENT_DETAILS';
const VIEWED_TERMS_OF_SERVICE: 'VIEWED_TERMS_OF_SERVICE' = 'VIEWED_TERMS_OF_SERVICE';
const VIEWED_PRIVACY_POLICY: 'VIEWED_PRIVACY_POLICY' = 'VIEWED_PRIVACY_POLICY';
const CLOSED_SURVEY: 'CLOSED_SURVEY' = 'CLOSED_SURVEY';

const UPDATE_CLIENT_SECRET: 'UPDATE_CLIENT_SECRET' = 'UPDATE_CLIENT_SECRET';
const CLEAR_CLIENT_SECRET: 'CLEAR_CLIENT_SECRET' = 'CLEAR_CLIENT_SECRET';

/// --------------------------------
/// GET GATEWAY INTERFACES
/// --------------------------------

export interface IGetPaymentGatewaysRequestedAction extends Action {
    type: typeof GET_PAYMENT_GATEWAYS_REQUESTED;
}

export interface IGetPaymentGatewaysSuccessAction extends Action {
    type: typeof GET_PAYMENT_GATEWAYS_SUCCESS;
    data: Array<IPaymentGateway>;
}

export interface IGetPaymentGatewaysCompletedAction extends Action {
    type: typeof GET_PAYMENT_GATEWAYS_COMPLETED;
}

export interface IGetPaymentGatewaysFailedAction extends Action {
    type: typeof GET_PAYMENT_GATEWAYS_FAILED;
}

/// --------------------------------
/// STRIPE INTERFACES
/// --------------------------------

export interface IStripePaymentRequestedAction extends IAnalyticsAction {
    type: typeof STRIPE_PAYMENT_REQUESTED;
}

export interface IStripePaymentCompleteAction extends Action {
    type: typeof STRIPE_PAYMENT_COMPLETED;
}

export interface IStripePaymentActionRequiredAction extends Action {
    type: typeof STRIPE_PAYMENT_ACTION_REQUIRED;
    responseSecret: string;
}

export interface IStripePaymentSuccessAction extends Action {
    type: typeof STRIPE_PAYMENT_SUCCESS;
}

export interface IStripePaymentFailedAction extends IAnalyticsAction {
    type: typeof STRIPE_PAYMENT_FAILED;
}

/// --------------------------------
/// PAYPAL INTERFACES
/// --------------------------------

export interface IPaypalPaymentRequestedAction extends IAnalyticsAction {
    type: typeof PAYPAL_PAYMENT_REQUESTED;
}
export interface IPaypalPaymentSuccessAction extends Action {
    type: typeof PAYPAL_PAYMENT_SUCCESS;
}

export interface IPaypalPaymentCompletedAction extends Action {
    type: typeof PAYPAL_PAYMENT_COMPLETED;
}

export interface IPaypalPaymentFailedAction extends Action {
    type: typeof PAYPAL_PAYMENT_FAILED;
}

export interface IPaypalPaymentCancelledAction extends Action {
    type: typeof PAYPAL_PAYMENT_CANCELLED;
}

/// --------------------------------
/// CHECKOUT INTERFACES
/// --------------------------------

export interface ICheckoutPaymentRequestedAction extends IAnalyticsAction {
    type: typeof CHECKOUT_PAYMENT_REQUESTED;
}
export interface ICheckoutPaymentSuccessAction extends Action {
    type: typeof CHECKOUT_PAYMENT_SUCCESS;
}

export interface ICheckoutPaymentCompletedAction extends Action {
    type: typeof CHECKOUT_PAYMENT_COMPLETED;
}

export interface ICheckoutPaymentFailedAction extends Action {
    type: typeof CHECKOUT_PAYMENT_FAILED;
}

export interface IBackProcessingAddedAction extends Action {
    type: typeof BACK_PROCESSING_ADDED;
}

export interface IUpdateClientSecretAction extends Action {
    type: typeof UPDATE_CLIENT_SECRET;
    clientSecret?: string;
}

export interface IClearClientSecretAction extends Action {
    type: typeof CLEAR_CLIENT_SECRET;
}

export type KnownPaymentActions =
    | IGetPaymentGatewaysRequestedAction
    | IGetPaymentGatewaysCompletedAction
    | IGetPaymentGatewaysFailedAction
    | IGetPaymentGatewaysSuccessAction
    | IPaypalPaymentRequestedAction
    | IPaypalPaymentFailedAction
    | IPaypalPaymentCancelledAction
    | IPaypalPaymentSuccessAction
    | IPaypalPaymentCompletedAction
    | ICheckoutPaymentRequestedAction
    | ICheckoutPaymentFailedAction
    | ICheckoutPaymentSuccessAction
    | ICheckoutPaymentCompletedAction
    | IStripePaymentRequestedAction
    | IStripePaymentCompleteAction
    | IStripePaymentSuccessAction
    | IStripePaymentFailedAction
    | IStripePaymentActionRequiredAction
    | IUpdateClientSecretAction
    | IClearClientSecretAction
    | CallHistoryMethodAction
    | IBackProcessingAddedAction
    | IDiscountCodeApplied
    | IClearBookingAction
    | IExpireBookingSuccessAction
    | IExpireBookingCompletedAction
    | IUpdateGiftVoucherAmountSuccessAction
    | IClearGiftVoucherAction
    | ICancelGiftVoucherSuccessAction
    | ICancelGiftVoucherCompleteAction;

export const actionCreators = {
    getPaymentGateways: (): IAppThunkAction<KnownPaymentActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();
        if (appState &&
            (appState.booking.booking || appState.giftVoucher.giftVoucher) &&
            appState.sites.selectedSite?.siteId !== undefined) {
            const siteId = appState.sites.selectedSite.siteId;

            PaymentServices.GetPaymentGateways(siteId)
                .then((data) => {
                    dispatch({ type: GET_PAYMENT_GATEWAYS_SUCCESS, data: data });
                    dispatch({ type: GET_PAYMENT_GATEWAYS_COMPLETED });
                })
                .catch((error: Error) => {
                    dispatch({ type: GET_PAYMENT_GATEWAYS_FAILED });
                    dispatch({ type: GET_PAYMENT_GATEWAYS_COMPLETED });
                    // appInsights?.trackException(
                    //     { error: new Error(`Error getting payment gateways`), severityLevel: 5}, 
                    //     { exception: error, sourceFile: "payments.ts", product: "booking" });
                });

            dispatch({ type: GET_PAYMENT_GATEWAYS_REQUESTED });
        }
    },

    stripePaymentStarted: (): IAppThunkAction<KnownPaymentActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();
        const charge = appState.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher ? appState.giftVoucher.charge : appState.booking.charge;
        const isEmailSubscribed = appState.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher ? appState.giftVoucher.emailSubscribed : appState.booking.emailSubscribed;
        const isSmsSubscribed = appState.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher ? appState.giftVoucher.smsSubscribed : appState.booking.smsSubscribed;

        const callingCode = appState.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher ? appState.giftVoucher.callingCode : appState.booking.callingCode;
        const isAuNumber = callingCode === BrazeEvents.AuNumber;

        const isReservation = appState?.sites?.selectedSite?.reservationPortal ?? false;
        const isGiftVoucher = appState?.purchaseType?.selectedPurchaseType === PurchaseTypes.GiftVoucher;

        const country = appState?.sites?.selectedSite?.country ?? 'Unknown';
        const siteName = appState?.sites?.selectedSite?.name ?? 'Unknown';

        dispatch({
            type: STRIPE_PAYMENT_REQUESTED,
        });

        if (charge) {
            BrazeEvents.PaymentInitiated(isEmailSubscribed, isSmsSubscribed, isAuNumber, isReservation, isGiftVoucher, charge, country, siteName);
        }
    },
    stripePaymentRequested: (emailAddress: string) =>
        ({ type: STRIPE_PAYMENT_REQUESTED } as IStripePaymentRequestedAction),
    stripePaymentFailed: () => ({
        type: STRIPE_PAYMENT_FAILED,
        googleTagManagerEvent: GoogleTagManagerEvents.PaymentFailed('stripe')
    } as IStripePaymentFailedAction),
    stripePaymentSuccess: () => ({ type: STRIPE_PAYMENT_SUCCESS } as IStripePaymentSuccessAction),
    stripePaymentStatus: (paymentIntent: string): IAppThunkAction<KnownPaymentActions> => (dispatch: any, getState) => {
        const appState: IApplicationState = getState();

        const siteId = appState.purchaseType.selectedPurchaseType === PurchaseTypes.Booking || appState.purchaseType.selectedPurchaseType === PurchaseTypes.PrivateEvent
            ? appState.booking.booking?.siteId
            : appState.giftVoucher.giftVoucher?.siteId;
        const paymentId = appState.purchaseType.selectedPurchaseType === PurchaseTypes.Booking || appState.purchaseType.selectedPurchaseType === PurchaseTypes.PrivateEvent
            ? appState.booking.booking?.paymentId
            : appState.giftVoucher.giftVoucher?.paymentId;

        if (siteId && paymentId) {
            PaymentServices.StripePaymentStatus(paymentIntent, siteId, paymentId)
                .then((data) => {
                    if (data.failureCode != null) {
                        let newError = new Error(data.failureMessage);
                        newError.name = 'Stripe Payment Error';
                        Logging.LogError(newError);
                        dispatch(UIActions.displayError('errors.payments.declined'));
                        dispatch(actionCreators.stripePaymentFailed());
                        dispatch(push(`/checkout/${appState.sites.selectedSite?.urlKey}`));
                    }
                });
        }
    },

    paypalPaymentStarted: (): IAppThunkAction<KnownPaymentActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();
        const charge = appState.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher ? appState.giftVoucher.charge : appState.booking.charge;
        const isEmailSubscribed = appState.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher ? appState.giftVoucher.emailSubscribed : appState.booking.emailSubscribed;
        const isSmsSubscribed = appState.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher ? appState.giftVoucher.smsSubscribed : appState.booking.smsSubscribed;

        const callingCode = appState.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher ? appState.giftVoucher.callingCode : appState.booking.callingCode;
        const isAuNumber = callingCode === BrazeEvents.AuNumber;

        const isReservation = appState?.sites?.selectedSite?.reservationPortal ?? false;
        const isGiftVoucher = appState?.purchaseType?.selectedPurchaseType === PurchaseTypes.GiftVoucher;

        const country = appState?.sites?.selectedSite?.country ?? 'Unknown';
        const siteName = appState?.sites?.selectedSite?.name ?? 'Unknown';

        dispatch({
            type: PAYPAL_PAYMENT_REQUESTED,
        });

        if (charge) {
            BrazeEvents.PaymentInitiated(isEmailSubscribed, isSmsSubscribed, isAuNumber, isReservation, isGiftVoucher, charge, country, siteName);
        }
    },
    paypalPaymentCancelled: () => ({ type: PAYPAL_PAYMENT_CANCELLED } as IPaypalPaymentCancelledAction),
    paypalPaymentError: () => ({ type: PAYPAL_PAYMENT_FAILED } as IPaypalPaymentFailedAction),
    paypalPaymentSuccess: () => ({ type: PAYPAL_PAYMENT_SUCCESS } as IPaypalPaymentSuccessAction),

    checkoutPaymentStarted: (): IAppThunkAction<KnownPaymentActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();
        const charge = appState.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher ? appState.giftVoucher.charge : appState.booking.charge;
        const isEmailSubscribed = appState.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher ? appState.giftVoucher.emailSubscribed : appState.booking.emailSubscribed;
        const isSmsSubscribed = appState.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher ? appState.giftVoucher.smsSubscribed : appState.booking.smsSubscribed;

        const callingCode = appState.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher ? appState.giftVoucher.callingCode : appState.booking.callingCode;
        const isAuNumber = callingCode === BrazeEvents.AuNumber;

        const isReservation = appState?.sites?.selectedSite?.reservationPortal ?? false;
        const isGiftVoucher = appState?.purchaseType?.selectedPurchaseType === PurchaseTypes.GiftVoucher;

        const country = appState?.sites?.selectedSite?.country ?? 'Unknown';
        const siteName = appState?.sites?.selectedSite?.name ?? 'Unknown';

        dispatch({ type: CHECKOUT_PAYMENT_REQUESTED });

        if (charge) {
            BrazeEvents.PaymentInitiated(isEmailSubscribed, isSmsSubscribed, isAuNumber, isReservation, isGiftVoucher, charge, country, siteName);
        }
    },
    checkoutPaymentSuccess: () => ({ type: CHECKOUT_PAYMENT_SUCCESS } as ICheckoutPaymentSuccessAction),
    checkoutPaymentFailed: () => ({ type: CHECKOUT_PAYMENT_FAILED } as ICheckoutPaymentFailedAction),

    backProcessingAdded: () => ({ type: BACK_PROCESSING_ADDED } as IBackProcessingAddedAction),

    validPaymentDetails: (gateway: string) => ({
        type: VALID_PAYMENT_DETAILS,
        googleTagManagerEvent: GoogleTagManagerEvents.ValidPaymentDetails(gateway)
    } as IAnalyticsAction),
    viewedTermsOfService: () => ({
        type: VIEWED_TERMS_OF_SERVICE,
        googleTagManagerEvent: GoogleTagManagerEvents.ClickedReadTOS()
    } as IAnalyticsAction),
    viewedPrivacyPolicy: () => ({
        type: VIEWED_PRIVACY_POLICY,
        googleTagManagerEvent: GoogleTagManagerEvents.ClickedReadPrivacyPolicy()
    } as IAnalyticsAction),
    closedSurvey: () => ({
        type: CLOSED_SURVEY,
        googleTagManagerEvent: GoogleTagManagerEvents.ClosedSurvey()
    } as IAnalyticsAction),

    updateClientSecret: (clientSecret: string) => ({
        type: UPDATE_CLIENT_SECRET,
        clientSecret: clientSecret,
    } as IUpdateClientSecretAction),

    clearClientSecret: () => ({ type: CLEAR_CLIENT_SECRET } as IClearClientSecretAction),
};

const undefinedState: IPaymentState = {
    stripe: {
        apiKey: undefined,
    },
    paypal: {
        apiKey: undefined,
    },
    checkout: {
        apiKey: undefined,
    },
    loading: false,
    backProcessing: false,
    clientSecret: undefined,
};

export const reducer: Reducer<IPaymentState> = (
    state: IPaymentState | undefined,
    incomingAction: Action
): IPaymentState => {
    const action: KnownPaymentActions = incomingAction as KnownPaymentActions;
    if (state === undefined) {
        console.log('Returning undefined payment state');
        return undefinedState;
    }

    switch (action.type) {
        case UPDATE_GIFT_VOUCHER_AMOUNT_SUCCESS:
        case CLEAR_GIFT_VOUCHER:
        case CANCEL_GIFT_VOUCHER_SUCCESS:
        case CANCEL_GIFT_VOUCHER_COMPLETE:
        case EXPIRE_BOOKING_SUCCESS:
        case EXPIRE_BOOKING_COMPLETE:
        case CLEAR_BOOKING:
        case DISCOUNT_CODE_APPLIED: {
            return {
                ...state,
                clientSecret: undefined
            };
        }
        case GET_PAYMENT_GATEWAYS_SUCCESS: {
            const paypalDetails =
                action.data.find((d) => d.gateway.toLowerCase() === PaymentGateway.PayPal.toLowerCase()) || undefined;
            const checkoutDetails =
                action.data.find((d) => d.gateway.toLowerCase() === PaymentGateway.Checkout.toLowerCase()) || undefined;
            const stripeDetails =
                action.data.find((d) => d.gateway.toLowerCase() === PaymentGateway.Stripe.toLowerCase()) || undefined;

            return {
                paypal: {
                    apiKey: paypalDetails?.apiKey ?? undefined,
                },
                checkout: {
                    apiKey: checkoutDetails?.apiKey ?? undefined,
                },
                stripe: {
                    apiKey: stripeDetails?.apiKey ?? undefined,
                    paymentMethodType: stripeDetails?.paymentMethodType ?? undefined
                },
                loading: false,
                backProcessing: state.backProcessing,
                clientSecret: state.clientSecret,
            };
        }

        case STRIPE_PAYMENT_REQUESTED: {
            return {
                paypal: state.paypal,
                stripe: state.stripe,
                checkout: state.checkout,
                loading: true,
                clientSecret: state.clientSecret,
                backProcessing: state.backProcessing
            };
        }
        case PAYPAL_PAYMENT_REQUESTED: {
            return {
                paypal: state.paypal,
                stripe: state.stripe,
                checkout: state.checkout,
                loading: true,
                clientSecret: state.clientSecret,
                backProcessing: state.backProcessing
            };
        }
        case CHECKOUT_PAYMENT_REQUESTED:
            return {
                paypal: state.paypal,
                stripe: state.stripe,
                checkout: state.checkout,
                loading: true,
                clientSecret: state.clientSecret,
                backProcessing: state.backProcessing
            };

        case STRIPE_PAYMENT_FAILED:
        case PAYPAL_PAYMENT_FAILED:
        case PAYPAL_PAYMENT_CANCELLED:
            return {
                paypal: state.paypal,
                stripe: state.stripe,
                checkout: state.checkout,
                loading: false,
                clientSecret: state.clientSecret,
                backProcessing: state.backProcessing
            };

        case STRIPE_PAYMENT_COMPLETED:
        case PAYPAL_PAYMENT_COMPLETED:
        case CHECKOUT_PAYMENT_COMPLETED:
            return {
                paypal: state.paypal,
                stripe: state.stripe,
                checkout: state.checkout,
                loading: false,
                clientSecret: state.clientSecret,
                backProcessing: state.backProcessing
            };
        case BACK_PROCESSING_ADDED:
            return {
                paypal: state.paypal,
                stripe: state.stripe,
                checkout: state.checkout,
                loading: state.loading,
                clientSecret: state.clientSecret,
                backProcessing: true
            };

        case UPDATE_CLIENT_SECRET:
            return {
                paypal: state.paypal,
                stripe: state.stripe,
                checkout: state.checkout,
                loading: state.loading,
                clientSecret: action.clientSecret,
                backProcessing: true
            };

        case CLEAR_CLIENT_SECRET:
            return {
                paypal: state.paypal,
                stripe: state.stripe,
                checkout: state.checkout,
                loading: state.loading,
                clientSecret: undefined,
                backProcessing: state.backProcessing
            };

        default:
            return state;
    }
};
