import { Col, Row } from 'antd';
import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import VoucherAmountPicker from '../../components/voucher-amount-picker/voucher-amount-picker';
import { IGiftVoucherConfig } from '../../interfaces/IGiftVoucherConfig';
import { IApplicationState } from '../../store';
import { actionCreators as GiftVoucherActions } from '../../store/GiftVoucher';

export interface IVoucherAmountFixedProps {
    voucherConfig?: IGiftVoucherConfig | undefined,
}

const VoucherAmountFixed: React.FunctionComponent<IVoucherAmountFixedProps> = (props) => {
    const dispatch = useDispatch();
    const params: any = useParams();

    const locale = useSelector((state: IApplicationState) => state.intl.currentLocale);
    const selectedGiftVoucherButton = useSelector((state: IApplicationState) => state.giftVoucher.selectedFixedAmountButton);
    const currencySymbol = useSelector((state: IApplicationState) => state.sites.selectedSite?.currencySymbol);
    const discountPercentage = useSelector((state: IApplicationState) => state.giftVoucher.configDetails?.activeDiscount?.discountPercentage);

    const amountSelected = useCallback(
        (selectedValue: number, selectedButton: number) => {
            dispatch(GiftVoucherActions.clearField);
            dispatch(GiftVoucherActions.buttonSelected(selectedValue, selectedButton));
        },
        [dispatch]
    );

    return (
        <React.Fragment>
            <Row justify="start" align="top">
                <Col span={24}>
                    <VoucherAmountPicker
                        locale={locale}
                        defaultSelected={selectedGiftVoucherButton}
                        min={1}
                        max={8}
                        config={props.voucherConfig}
                        currencySymbol={currencySymbol ?? ""}
                        onChange={amountSelected}
                        discountPercentage={discountPercentage}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default VoucherAmountFixed