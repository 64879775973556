import { Form, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../store';

interface ICurrencyInputProps {
    placeHolder: string;
    className: string;
    fieldDecorator: string;
    hasFeedback: boolean;
    onChange: (value: number) => void;
    minAmount: number;
    maxAmount: number;
    prefix: string;
}

const CurrencyInput: React.FunctionComponent<ICurrencyInputProps> = (props: ICurrencyInputProps): React.ReactElement => {
    const fixedAmountSelected = useSelector((state: IApplicationState) => state.giftVoucher.selectedFixedAmountButton) ?? 0;
    const [amount, setAmount] = useState<number | undefined>();
    const [form] = Form.useForm();

    const textValidationRules: Array<any> = [
        { required: true, message: 'Required' },
        () => ({
            validator(_: any, value: number) {
                if (value < props.minAmount) {
                    return Promise.reject(new Error(`The value cannot be less than ${props.minAmount}`));
                }
                if (value > props.maxAmount) {
                    return Promise.reject(new Error(`The value cannot be more than ${props.maxAmount}`));
                }
                return Promise.resolve();
            },
        }),
    ];

    useEffect(() => {
        if (fixedAmountSelected > 0) {
            form.resetFields();
        }
    }, [fixedAmountSelected])

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const newAmount = parseFloat(e.target.value).toFixed(2); 
        const roundedAmount = Number(newAmount);

        if (roundedAmount >= props.minAmount && roundedAmount <= props.maxAmount) {
            setAmount(roundedAmount);
            props.onChange(roundedAmount);
            form.setFieldsValue({ [props.fieldDecorator]: roundedAmount }); 
        }
        else {
            form.resetFields();
        }
    }

    return (
        <Form form={form}>
            <Form.Item
                name={props.fieldDecorator}
                className={props.className}
                hasFeedback={props.hasFeedback}
                rules={textValidationRules}
            >
                <Input
                    style={{ width: '100%' }}
                    prefix={props.prefix}
                    className="zl-input"
                    type='number'
                    value={amount?.toFixed(2)}
                    onBlur={handleBlur}
                />
            </Form.Item>
        </Form>
    );
};

export default CurrencyInput;
