import './booking-summary.scss';

import SummaryHeader from './summary-header';
import SummaryModifyButton from './summary-modify-button';
import SummaryItem from './summary-item';
import SummaryCheckout from './summary-checkout';
import SummarySubtotal from './summary-subtotal';

import { CaretDownFilled } from '@ant-design/icons';
import { Affix, Col, Collapse, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import numberHelpers from '../../helpers/number';

import ZLSection from '../../containers/zl-section/zl-section';

import PackageTierPrice from '../../interfaces/IPackageTierPrice';

import { ISummarySubtotalItemProps } from './summary-subtotal-item';
import { IApplicationState } from '../../store';
import { actionCreators as BookingActions, BookingStatus } from '../../store/Booking';
import { actionCreators as GiftVoucherActions, GiftVoucherStatus } from '../../store/GiftVoucher';
import { actionCreators as PlayerActions } from '../../store/Players';
import { actionCreators as SessionActions } from '../../store/Sessions';

import pluralHelper from '../../helpers/plural';

import IPackageLanguage from '../../interfaces/IPackageLanguage';
import ZLButton, { ButtonType } from '../zl-button/zl-button';
import { IDiscountCode } from '../../interfaces/IDiscountCode';
import adBlockChecker from '../../helpers/adBlockChecker';
import { PurchaseTypes } from '../../store/PurchaseTypes';
import SummaryPrivacy from './summary-privacy';

const { Panel } = Collapse;

interface PurchaseDetailsProps {
    isFullWidth: boolean;
}

export interface IBookingSummaryProps {
    active?: boolean; //toggles display for the embedded version
    affix?: boolean; //regular desktop affix
    drawer?: boolean; //affix drawer style
    scrollTo?: boolean;
}

const BookingSummary: React.FunctionComponent<IBookingSummaryProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const selectedPurchaseType = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseType);

    const bookingState = useSelector((state: IApplicationState) => state.booking);
    const giftVoucherState = useSelector((state: IApplicationState) => state.giftVoucher);

    const locale = useSelector((state: IApplicationState) => state.intl.currentLocale);
    const site = useSelector((state: IApplicationState) => state.sites.selectedSite);
    const players = useSelector((state: IApplicationState) => state.players.selectedPlayers);
    const playersUI = useSelector((state: IApplicationState) => state.players.selectedPlayersUI);
    const selectedPrivateUpgrade = useSelector((state: IApplicationState) => state.players.selectedPrivateUpgrade);

    const date = useSelector((state: IApplicationState) => state.session.selectedDate);
    const time = useSelector((state: IApplicationState) => state.session.selectedTime);
    const session = useSelector((state: IApplicationState) => state.session.selectedSession);
    const sessionUI = useSelector((state: IApplicationState) => state.session.selectedSessionUI);

    const discountCodes = useSelector((state: IApplicationState) => state.booking.appliedCodes);
    const pendingBooking = useSelector((state: IApplicationState) => state.booking.booking);
    const bookingStatus = useSelector((state: IApplicationState) => state.booking.status);
    const feeAmount = useSelector((state: IApplicationState) => state.sites.bookingOptions?.feeAmount);
    const feePercent = useSelector((state: IApplicationState) => state.sites.bookingOptions?.feePercent);
    const reservationBooking = useSelector((state: IApplicationState) => state.sites.selectedSite?.reservationPortal);

    const bookingLoading = useSelector((state: IApplicationState) => state.booking.loading);
    const giftVoucherLoading = useSelector((state: IApplicationState) => state.giftVoucher.loading);

    const bookingCharge = useSelector((state: IApplicationState) => state.booking.charge);
    const giftVoucherCharge = useSelector((state: IApplicationState) => state.giftVoucher.charge);

    const voucherAmount = useSelector((state: IApplicationState) => state.giftVoucher.amount);
    const pendingVoucher = useSelector((state: IApplicationState) => state.giftVoucher.giftVoucher);
    const toRecipient = useSelector((state: IApplicationState) => state.giftVoucher.toRecipientSelected);
    const recipientName = useSelector((state: IApplicationState) => state.giftVoucher.toFullName);
    const recipientEmail = useSelector((state: IApplicationState) => state.giftVoucher.toEmail);
    const recipientMessage = useSelector((state: IApplicationState) => state.giftVoucher.message);
    const giftVoucherStatus = useSelector((state: IApplicationState) => state.giftVoucher.status);

    const attractionTypes = useSelector((state: IApplicationState) => state.sites.bookingOptions?.attractionTypes);
    
    const [panelOpen, setPanelOpen] = useState(false);
    const activeKey = panelOpen ? 'summary' : '';
    const ticketsPage = location.pathname.startsWith('/book-now') || location.pathname.startsWith('/private-event') || location.pathname === '/';
    const giftVouchersPage = location.pathname.startsWith('/gift-voucher');
    const checkoutPage = location.pathname.startsWith('/checkout');
    const gamePackage = session?.package;
    const priceTierTypeId = session?.priceTierTypeId;
    const packageTierPrice = session?.package.packageTierPrices.find(
        (ptp: PackageTierPrice) => ptp.priceTierType.priceTierTypeId === priceTierTypeId
    );

    const attractionType =  attractionTypes?.find(a => a.attractionTypeId === session?.gameSpace.attractionTypeId);

    const packagePurchase = selectedPurchaseType === PurchaseTypes.Booking || selectedPurchaseType === PurchaseTypes.PrivateEvent;

    const loading = packagePurchase ? bookingLoading : giftVoucherLoading;
    const charge = packagePurchase ? bookingCharge : giftVoucherCharge;

    const completedVoucherDetails =
        recipientEmail !== undefined
        && recipientName !== undefined
    
    const completedVoucherAmount = (voucherAmount ?? 0) > 0;

    const bookingCheckoutEnabled = (packagePurchase
        && (packageTierPrice?.price !== undefined || charge !== undefined))
        && ticketsPage;

    const giftVoucherCheckoutEnabled = selectedPurchaseType === PurchaseTypes.GiftVoucher
        && completedVoucherAmount 
        && (completedVoucherDetails || !(toRecipient ?? true))
        && giftVouchersPage;

    const checkoutEnabled = bookingCheckoutEnabled || giftVoucherCheckoutEnabled;

    const pricing = packageTierPrice?.price;
    const currencyLocale = charge?.currencyLocale ?? pricing?.currencyLocale;
    const currencyCode = charge?.currencyCode ?? pricing?.currencyCode ?? '';
    const taxInclusive = charge?.taxInclusive ?? pricing?.taxInclusive ?? false;
    const quantity = charge?.quantity ?? players ?? 1;

    let tax = charge?.tax ?? pricing?.tax ?? 0;
    let fee = charge?.fee ?? 0;
    let cost = charge?.cost ?? pricing?.cost ?? 0;
    let total = charge?.total ?? pricing?.total ?? 0;

    if(gamePackage?.perPersonPricing) {
        cost *= quantity;
    }

    let giftVoucherTax = charge?.tax ?? site?.taxPercentage ?? 0;
    let giftVoucherFee = charge?.fee ?? 0;
    let giftVoucherCost = charge?.cost ?? voucherAmount ?? 0;
    let giftVoucherTotal = charge?.total ?? voucherAmount ?? 0;

    let adBlockDetected = false;
    adBlockChecker.IsAdBlockEnabled().then((flag) => {
        adBlockDetected = flag;
    });

    const missingPlayers = session && session.maximumSlots === session.remainingSlots ? session.maximumSlots - (players ?? 8) : 0;
    let privateSessionCost = session ? (missingPlayers * (pricing?.cost ?? 0) * session.package.privacyChargePercentage / 100) : 0;
    const privateSessionTax = session ?  (missingPlayers * (pricing?.tax ?? 0) * session.package.privacyChargePercentage / 100) : 0;

    //a package has been selected but there is no charge object from the server.
    //pricing from packages are based on 1 ticket, so the tax and total needs to be for all tickets
    if (charge === undefined && pricing !== undefined && players !== undefined) {
        if(gamePackage?.perPersonPricing) {
            tax *= players;
            total *= players;

            if(taxInclusive) {
                cost += tax;
            }
        }

        if(gamePackage?.privacyTypeId === 2 && selectedPrivateUpgrade) {
            tax += privateSessionTax;
            total += privateSessionCost + privateSessionTax;
        }
    }
    
    if(taxInclusive) {
        privateSessionCost += privateSessionTax;
    }

    if(feeAmount !== undefined && feePercent !== undefined && pricing !== undefined && charge === undefined) {
        fee = feeAmount + (total * (feePercent / 100));
        total += fee;
    }

    const title = props.affix
        ? t('bookingSummary.titles.summary') //affixed title
        : t('bookingSummary.titles.purchaseSummary'); // all other titles

    const venueTitle = t('bookingSummary.labels.venue');
    const playersTitle = t('bookingSummary.labels.players');
    const dateTitle = t('bookingSummary.labels.date');
    const timeTitle = t('bookingSummary.labels.sessionTime');
    const checkoutText = t('bookingSummary.labels.checkout');
    const modifyText = t('bookingSummary.labels.modify');
    const packageTitle = t('bookingSummary.labels.experiences');

    const amountTitle = t('giftVoucherSection.summary.amountLabel');
    const recipientDetailsTitle = t('giftVoucherSection.summary.detailsLabel');
    const recipientMessageTitle = t('giftVoucherSection.summary.messageLabel');
    const expirationMessageTitle = t('giftVoucherSection.details.expirationLabel');

    const arriveEarlyTime = attractionType?.arrivalTimeMinutes ?? 0;

    const arriveEarlyText = (arriveEarlyTime && arriveEarlyTime > 0) ? (reservationBooking
        ? t('arriveEarlyReservation', { value: arriveEarlyTime })
        : t('arriveEarly', { value: arriveEarlyTime })) : undefined;

    const playersText =
        players !== undefined
            ? t(pluralHelper.getKey(locale, 'bookingSummary.labels.numberOfPlayers', players), { count: players })
            : '';

    const dateText = date !== undefined ? moment(date).format('D MMMM YYYY') : '';

    const startTime = moment(time?.time).utc().format('LT');
    const endTime =
        gamePackage !== undefined
            ? `${moment(time?.time)
                  .add(gamePackage?.experienceDurationMinutes ?? 0, 'minutes')
                  .utc()
                  .format('LT')}`
            : undefined;

    const timeText = time && endTime ? `${startTime} - ${endTime}` : endTime === undefined ? `${startTime}` : '';

    const packageName =
        gamePackage !== undefined
            ? gamePackage.languageData.find((language: IPackageLanguage) => language.locale === locale)?.name ??
              gamePackage.languageData.length > 0
                ? gamePackage.languageData[0]?.name ?? gamePackage.alias
                : ''
            : '';

    const voucherAmountText =
        voucherAmount !== undefined
            ? voucherAmount.toFixed(2).toString()
            : '';

    const modifyClicked = useCallback(() => {
        if (packagePurchase) {
            if (pendingBooking) {
                dispatch(BookingActions.modifyBooking(pendingBooking));
            }
        }
        else if (selectedPurchaseType === PurchaseTypes.GiftVoucher) {
            if (pendingVoucher) {
                dispatch(GiftVoucherActions.modifyGiftVoucher(pendingVoucher));
            }
        }
    }, [pendingBooking, pendingVoucher, dispatch]);

    const privacySelected = useCallback(
        (isPrivate: boolean) => {
            if (pendingBooking && bookingStatus === BookingStatus.Pending && selectedPrivateUpgrade !== isPrivate) {
                dispatch(BookingActions.expireBooking());
            }

            dispatch(PlayerActions.selectPrivateUpgrade(isPrivate));
        },
        [dispatch, pendingBooking, bookingStatus, selectedPrivateUpgrade]
    );

    const checkoutClicked = useCallback(() => {
        //when moving to the checkout page, make sure to abandon any old data that is no longer relevant
        if (packagePurchase) {
            dispatch(GiftVoucherActions.cancelGiftVoucher());
            dispatch(GiftVoucherActions.clearGiftVoucher());

            if (pendingBooking && bookingStatus === BookingStatus.Pending) {
                dispatch(BookingActions.continuePendingBooking());
            } else if (session) {
                dispatch(BookingActions.reserveBooking(session, adBlockDetected));
            }
        }
        else if (selectedPurchaseType === PurchaseTypes.GiftVoucher) {
            dispatch(BookingActions.expireBooking());
            dispatch(BookingActions.clearBooking());
            dispatch(PlayerActions.clearPlayers());
            dispatch(SessionActions.clearSelectedDateTime());
            dispatch(SessionActions.clearSelectedTime());

            if (giftVoucherStatus === GiftVoucherStatus.Pending) {
                dispatch(GiftVoucherActions.updateGiftVoucherAmount(() => dispatch(GiftVoucherActions.continuePendingGiftVoucher())));
                
            } else if (giftVoucherState && site !== undefined) {
                dispatch(GiftVoucherActions.reserveGiftVoucher(site.siteId, adBlockDetected));
            }
        }

    }, [giftVoucherState, site, pendingBooking, bookingStatus, giftVoucherStatus, session, dispatch, selectedPurchaseType]);

    const toggleDrawer = useCallback(() => {
        setPanelOpen(!panelOpen);
    }, [panelOpen]);


    // Formatting for Bookings
    const venueNode = site ? (
        <React.Fragment>
            <Text>{`${site.siteMetadata.addressLine1}`}</Text>
            <br />
            <Text>{`${site.siteMetadata.addressLine2}`}</Text>
            <br />
            <Text>{`${site.siteMetadata.addressLine3}`}</Text>
        </React.Fragment>
    ) : (
        <React.Fragment />
    );

    const modifyButton = checkoutPage ? (
        <SummaryModifyButton text={modifyText} onClick={modifyClicked} />
    ) : (
        <React.Fragment />
    );

    const formattedPlayers = players ? <Text>{playersText}</Text> : undefined;
    const formattedDate = date ? <Text>{dateText}</Text> : undefined;
    const formattedDuration = numberHelpers.toNumber(locale, gamePackage?.experienceDurationMinutes ?? 0);
    const formattedTime = time ? (
        <React.Fragment>
            <Text>{timeText}</Text>
            <br />
            <Text>{arriveEarlyText}</Text>
        </React.Fragment>
    ) : undefined;

    const formattedPackage = gamePackage ? (
        <Text>{t('bookingSummary.labels.gamePackageValue', { name: packageName, duration: formattedDuration })}</Text>
    ) : undefined;

    // Formatting for Gift Vouchers
    const formattedVoucherAmount = voucherAmount ? <Text>{site?.paypalCode + " " + voucherAmountText}</Text> : undefined;
    const formattedRecipientDetails = recipientName && recipientEmail ? (
        <React.Fragment>
            <Text>{recipientName}</Text>
            <br />
            <Text>{recipientEmail}</Text>
        </React.Fragment>
    ) : undefined;
    const formattedMessage = recipientMessage ? <Text>{recipientMessage}</Text> : undefined;

    const expiryYears = useSelector((state: IApplicationState) => state.giftVoucher.configDetails?.expiryYears);
    
    let expirationDate = new Date()
    let expirationDateString = expirationDate.toDateString();

    if (expiryYears) {
        expirationDate.setFullYear(expirationDate.getFullYear() + expiryYears); 
        expirationDateString = expirationDate.toDateString();
        let parts = expirationDateString.split(' ');
        expirationDateString = parts.slice(1).join(' ');
    } 

    const formattedExpirationDate = (expiryYears) ? <Text>{expirationDateString}</Text> : "";

    //Subtotals
    let subtotalItems: Array<ISummarySubtotalItemProps> = [];

    let giftVoucherSubtotalItems: Array<ISummarySubtotalItemProps> = [];

    if(gamePackage?.perPersonPricing) {
        //players item
        if (players !== undefined) {
            // Remove Tax for display purposes
            subtotalItems = [
                ...subtotalItems,
                {
                    label: t(pluralHelper.getKey(locale, 'bookingSummary.labels.ticket', players), { count: players }),
                    value: cost,
                    currency: currencyCode,
                    locale: locale ?? currencyLocale,
                },
            ];
        }    
    } else {
        if(session) {
            // Remove Tax for display purposes
            subtotalItems = [
                ...subtotalItems,
                {
                    label:  t('statuses.players_range_requirement', { minimum: session?.minimumSlots, maximum: session?.maximumSlots }),
                    value: cost,
                    currency: currencyCode,
                    locale: locale ?? currencyLocale,
                },
            ];
        }
    }

    // Include Private Session Cost
    if(gamePackage?.privacyTypeId === 2 && selectedPrivateUpgrade && session) {
        subtotalItems = [
            ...subtotalItems,
            {
                label:  t('bookingSummary.labels.privateSession'),
                value: privateSessionCost,
                currency: currencyCode,
                locale: locale ?? currencyLocale,
            },
        ];
    }

    //players item
    if (voucherAmount !== undefined) {
        // Remove Tax for display purposes
        giftVoucherSubtotalItems = [
            ...giftVoucherSubtotalItems,
            {
                label: t('giftVoucherSection.summary.amountLabel'),
                value: voucherAmount,
                currency: currencyCode,
                locale: locale ?? currencyLocale,
            },
        ];
    }


    //tax item
    if (taxInclusive && tax > 0 && site?.showTaxAmountWhenBooking) {
        subtotalItems = [
            ...subtotalItems,
            {
                label: t('bookingSummary.labels.tax'),
                value: tax,
                currency: currencyCode,
                locale: locale ?? currencyLocale,
            },
        ];

        giftVoucherSubtotalItems = [
            ...giftVoucherSubtotalItems,
            {
                label: t('bookingSummary.labels.tax'),
                value: giftVoucherTax,
                currency: currencyCode,
                locale: locale ?? currencyLocale,
            },
        ];
    }

    //promo codes and discounts
    discountCodes.forEach((item: IDiscountCode) => {
        if (item.isPromoCode) {
            subtotalItems.push({
                label: item.isPromoCode ? item.code : t('bookingSummary.labels.giftVoucher'),
                value: item.amount * -1,
                currency: currencyCode,
                locale: locale ?? currencyLocale
            });
        }
    });

    //tax item
    if (!taxInclusive) {
        subtotalItems = [
            ...subtotalItems,
            {
                label: t('bookingSummary.labels.tax'),
                value: tax,
                currency: currencyCode,
                locale: locale ?? currencyLocale,
            },
        ];

        giftVoucherSubtotalItems = [
            ...giftVoucherSubtotalItems,
            {
                label: t('bookingSummary.labels.tax'),
                value: giftVoucherTax,
                currency: currencyCode,
                locale: locale ?? currencyLocale,
            },
        ];
    }

    //promo codes and discounts
    discountCodes.forEach((item: IDiscountCode) => {
        if (!item.isPromoCode) {
            subtotalItems.push({
                label: item.isPromoCode ? item.code : t('bookingSummary.labels.giftVoucher'),
                value: item.amount * -1,
                currency: currencyCode,
                locale: locale ?? currencyLocale
            });
        }
    });

    //tax item
    if (fee > 0) {
        subtotalItems = [
            ...subtotalItems,
            {
                label: t('bookingSummary.labels.fee'),
                value: fee,
                currency: currencyCode,
                locale: locale ?? currencyLocale,
            },
        ];

        giftVoucherSubtotalItems = [
            ...giftVoucherSubtotalItems,
            {
                label: t('bookingSummary.labels.fee'),
                value: giftVoucherFee,
                currency: currencyCode,
                locale: locale ?? currencyLocale,
            },
        ];
    }

    const totalItem: ISummarySubtotalItemProps = {
        label: t('bookingSummary.labels.total'),
        value: total,
        className: 'payable-total',
        currency: currencyCode,
        locale: locale ?? currencyLocale,
    };

    const taxItem: ISummarySubtotalItemProps | undefined =
        taxInclusive && tax > 0 && !site?.showTaxAmountWhenBooking
            ? {
                  label: 'bookingSummary.labels.includesTaxes',
                  long: true, //long items auto translate
                  value: tax,
                  className: 'includesTaxes',
                  currency: currencyCode,
                  locale: locale ?? currencyLocale,
              }
            : undefined;


    const giftVoucherTotalItem: ISummarySubtotalItemProps = {
        label: t('bookingSummary.labels.total'),
        value: giftVoucherTotal,
        className: 'payable-total',
        currency: currencyCode,
        locale: locale ?? currencyLocale,
    };

    const giftVoucherTaxItem: ISummarySubtotalItemProps | undefined =
        taxInclusive && giftVoucherTax > 0 && !site?.showTaxAmountWhenBooking
            ? {
                label: 'bookingSummary.labels.includesTaxes',
                long: true, //long items auto translate
                value: giftVoucherTax,
                className: 'includesTaxes',
                currency: currencyCode,
                locale: locale ?? currencyLocale,
            }
            : undefined;

    const PurchaseDetails: React.FunctionComponent<PurchaseDetailsProps> = ({ isFullWidth = true }) => {
        if (packagePurchase) {
            return (
                <div>
                    <SummaryItem title={playersTitle} value={formattedPlayers} fullWidth={isFullWidth} />
                    <SummaryItem title={dateTitle} value={formattedDate} fullWidth={isFullWidth} />
                    <SummaryItem title={timeTitle} value={formattedTime} fullWidth={isFullWidth} />
                    <SummaryItem title={packageTitle} value={formattedPackage} fullWidth={isFullWidth} />
                    <SummaryPrivacy 
                        privacyTypeId={!ticketsPage || privateSessionCost === 0 ? 0 : session?.package.privacyTypeId} 
                        upgradeChecked={selectedPrivateUpgrade}
                        onCheckChanged={privacySelected} />

                    <SummarySubtotal
                        currency={locale}
                        items={subtotalItems}
                        total={totalItem}
                        inclusiveTaxItem={taxItem} />
                </div>
            );
        }
        else if (selectedPurchaseType === PurchaseTypes.GiftVoucher) {
            return (
                <div>
                    <SummaryItem title={amountTitle} value={formattedVoucherAmount} fullWidth={isFullWidth} />
                    <RecipientDetails />
                    <SummaryItem title={expirationMessageTitle} value={formattedExpirationDate} fullWidth={isFullWidth} />
                    <SummarySubtotal
                        currency={locale}
                        items={giftVoucherSubtotalItems}
                        total={giftVoucherTotalItem}
                        inclusiveTaxItem={giftVoucherTaxItem}
                        hide={!checkoutPage} />
                </div>
            );
        }
        else {
            return <React.Fragment></React.Fragment>;
        }
    }

    const RecipientDetails: React.FunctionComponent = () => {
        if (toRecipient) {
            return (
                <div >
                    <SummaryItem title={recipientDetailsTitle} value={formattedRecipientDetails} fullWidth={true} />
                    <SummaryItem title={recipientMessageTitle} value={formattedMessage} fullWidth={true} />
                </div>);
        }
        else {
            return <React.Fragment></React.Fragment>;
        }
    }

    const hideBooking = site === undefined && players === undefined;
    const hideGiftVoucher = site === undefined && charge === undefined;

    const isHide = packagePurchase ? hideBooking : hideGiftVoucher;

    //desktop affix mode
    if (props.affix === true) {
        const show = isHide ? '' : 'show';
        return (
            <Affix offsetTop={charge ? 64 : 16}>
                <div className={`summary-container affixed ${show}`}>
                    <Row justify="start" align="top">
                        <Col span={24}>
                            <SummaryHeader
                                modify={checkoutPage}
                                modifyText={modifyText}
                                onModify={modifyClicked}
                                text={title}
                            />
                            <div className="summary-items">
                                <SummaryItem title={venueTitle} value={venueNode} fullWidth={true} />
                                <PurchaseDetails isFullWidth={true} />
                            </div>

                            <SummaryCheckout
                                enable={checkoutEnabled}
                                loading={loading}
                                text={checkoutText}
                                onCheckout={checkoutClicked}
                            />
                        </Col>
                    </Row>
                </div>
            </Affix>
        );
    }

    //mobile drawer style
    if (props.drawer === true) {
        const show = packagePurchase
            ? playersUI && (sessionUI || charge)
            : completedVoucherAmount && (completedVoucherDetails || !(toRecipient ?? true));
        const drawerClass = show ? 'show' : '';
        const pointerEvents = !show ? 'none' : 'all';
        return (
            <Affix offsetBottom={0} style={{ pointerEvents: pointerEvents }}>
                <div className={`summary-container drawer ${drawerClass}`}>
                    <Row justify="start" align="top">
                        <Col xs={24} sm={24} md={24} lg={0}>
                            <SummaryCheckout
                                enable={checkoutEnabled}
                                loading={loading}
                                text={checkoutText}
                                onCheckout={checkoutClicked}
                            />

                            <div className="drawer-toggle">
                                <ZLButton
                                    type={ButtonType.Link}
                                    onClick={toggleDrawer}
                                    className="drawer-toggle-button"
                                >
                                    <Text>{t('bookingSummary.labels.summary')}</Text>
                                    <CaretDownFilled rotate={180} className={activeKey !== '' ? 'active' : ''} />
                                </ZLButton>
                            </div>

                            <Collapse
                                className="zl-hidden-collapse summary-collapse"
                                bordered={false}
                                expandIcon={({ isActive }) => <React.Fragment />}
                                activeKey={activeKey}
                            >
                                <Panel header="" key="summary" className="summary-panel">
                                    <SummaryItem title={venueTitle} value={venueNode} />

                                    <PurchaseDetails isFullWidth={false} />
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </div>
            </Affix>
        );
    }

    //regular old embedded version
    return (
        <ZLSection active={props.active ?? true} title={title} action={modifyButton} scrollTo={props.scrollTo}>
            <Row justify="start" align="top" className="summary-container">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <PurchaseDetails isFullWidth={true} />
                    <SummaryCheckout
                        enable={checkoutEnabled}
                        loading={loading}
                        text={checkoutText}
                        onCheckout={checkoutClicked}
                    />
                </Col>
            </Row>
        </ZLSection>
    );
};

export default BookingSummary;
