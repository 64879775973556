import React from 'react';
import Icon from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import ZLButton, { ButtonSize, ButtonType } from '../zl-button/zl-button';
import { ReactComponent as pencil } from '../../images/svg/icons-edit.svg';

export interface IModifyButtonProps {
    text: string;
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const SummaryModifyButton: React.FunctionComponent<IModifyButtonProps> = (props) => {
    return (
        <ZLButton type={ButtonType.Link} className="modify-button" size={ButtonSize.Default} onClick={props.onClick}>
            <Icon component={pencil} className="zl-icon" />
            <Text>{props.text}</Text>
        </ZLButton>
    );
};

export default SummaryModifyButton;
