import './hint.scss';

import { CaretDownFilled } from '@ant-design/icons';
import { Collapse } from 'antd';
import React, { useState } from 'react';
import ZLButton, { ButtonType } from '../zl-button/zl-button';
import Text from 'antd/lib/typography/Text';
const { Panel } = Collapse;

export interface IHintProps {
    icon?: React.ReactElement;
    panelKey?: string | '';
    label?: string | '';
    children: React.ReactNode;
    onClick?: (isOpen: boolean) => void;
}

const Hint: React.FunctionComponent<IHintProps> = (props) => {
    const [isOpen, setOpen] = useState(false);
    const activeKey = isOpen && props.panelKey ? props.panelKey : '';

    const togglePanel = () => {
        const newOpenState = !isOpen;

        if (props.onClick) props.onClick(newOpenState);
        setOpen(newOpenState);
    };

    const NoCollapseHint: React.FunctionComponent = () => {
        return (
            <div className="zl-hint zl-hint-no-collapse">
                <div className="hint-icon">{props.icon}</div>
                {props.children}
            </div>
        );
    };

    if (props.panelKey === undefined) return <NoCollapseHint />;
    return (
        <div className="zl-hint">
            <ZLButton type={ButtonType.Link} onClick={togglePanel} className="hint-toggle">
                <Text>{props.label}</Text>
                <CaretDownFilled className={`hint-caret ${isOpen ? `active` : ''}`} />
            </ZLButton>
            <Collapse className="zl-hidden-collapse" bordered={false} activeKey={activeKey}>
                <Panel header="" key={props.panelKey} className="zl-hint-content">
                    {props.children}
                </Panel>
            </Collapse>
        </div>
    );
};

export default Hint;
