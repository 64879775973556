import React from 'react';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import numberHelpers from '../../helpers/number';

export interface ICurrencyProps {
    value: number;
    currency: string;
    locale: string;
    translationKey?: string | undefined;
    className?: string;
    showCode?: boolean;
    style?: React.CSSProperties;
}

const Currency: React.FunctionComponent<ICurrencyProps> = (props: ICurrencyProps) => {
    const { t } = useTranslation();
    const classNames: string = props.className ? props.className : '';

    if (props.locale === '' || props.locale === undefined) return <React.Fragment />;
    if (props.currency === '' || props.currency === undefined) return <React.Fragment />;

    const formattedValue = numberHelpers.toCurrency(
        props.locale,
        props.currency,
        props.value,
        undefined,
        props.showCode
    );

    if (props.translationKey === undefined)
        return (
            <Text className={classNames} style={props.style} data-testid="currency">
                {formattedValue}
            </Text>
        );

    return (
        <Text className={classNames} style={props.style} data-testid="currency">
            {t(props.translationKey, { value: formattedValue })}
        </Text>
    );
};

export default Currency;
