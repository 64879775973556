import { Col, Row, Empty } from 'antd';
import React, { createElement, useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Hint from '../../components/hint/hint';
import NumberPicker from '../../components/number-picker/number-picker';
import { IApplicationState, IAnalyticsAction } from '../../store';
import { actionCreators as PlayerActions } from '../../store/Players';
import { actionCreators as BookingActions, BookingStatus } from '../../store/Booking';
import { actionCreators as SessionActions } from '../../store/Sessions';
import { actionCreators as SiteActions } from '../../store/Sites';
import LoadingSpinner from '../../components/loading-spinner/loading-spinner';
import { GoogleTagManagerEvents } from '../../helpers/googleTagManager';
import moment from 'moment';
import { PurchaseTypes } from '../../store/PurchaseTypes';

const PlayerCount: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectedSessionTime = useSelector((state: IApplicationState) => state.session.selectedTime);
    const selectedPlayers = useSelector((state: IApplicationState) => state.players.selectedPlayers);
    const pendingBooking = useSelector((state: IApplicationState) => state.booking.booking);
    const bookingStatus: BookingStatus = useSelector((state: IApplicationState) => state.booking.status);
    const selectedSite = useSelector((state: IApplicationState) => state.sites.selectedSite);
    const bookingOptions = useSelector((state: IApplicationState) => state.sites.bookingOptions);
    const locale = useSelector((state: IApplicationState) => state.intl.currentLocale);
    const selectedGame = useSelector((state: IApplicationState) => state.games.selectedGame);
    const selectedDate = useSelector((state: IApplicationState) => state.session.selectedDate);
    const selectedPurchaseType = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseType);
    const privateEventsAvailable = useSelector((state: IApplicationState) => state.sites.bookingOptions?.privateEventsAvailable);
    const priceSets = useSelector((state: IApplicationState) => state.booking.priceSets);

    const priceSet = priceSets?.find((p) => p.venue === selectedSite?.urlKey);

    const emptyLogo = `/static/zl-black-new.png`;

    const isLoading = selectedSite?.siteId != bookingOptions?.siteId;
    const maximumPlayers = isLoading ? 0 : (selectedPurchaseType === PurchaseTypes.PrivateEvent ? (bookingOptions?.eventMaximumPlayers ?? 8) : (bookingOptions?.maximumPlayers ?? 8));
    const isOpen = isLoading ? false : (bookingOptions?.isOpen ?? false);
    
    const groupBookingLink =
        selectedSite?.groupBookingUrl !== undefined &&
        selectedSite?.groupBookingUrl !== '#' &&
        selectedSite?.groupBookingUrl !== ''
            ? selectedSite.groupBookingUrl
            : `https://zerolatencyvr.com/group-bookings`;

    const privateEventsLink = 'private-event/' + selectedSite?.urlKey;

    // Switched the system to always explicitly load booking options here.
    useEffect(() => {
        if(selectedSite) { // A site must be selected
            // If there are no booking options or if the options loaded don't match the selected site, load new ones.
            if(!bookingOptions || bookingOptions.siteId !== selectedSite.siteId) {
                dispatch(SiteActions.loadSiteBookingOptions(selectedSite, priceSet?.code));
            }
        }
    }, [dispatch, selectedSite, bookingOptions, priceSet]);

    const playersSelected = useCallback(
        (selectedValue: number) => {
            if (pendingBooking && bookingStatus === BookingStatus.Pending) {
                dispatch(BookingActions.expireBooking());
                dispatch(SessionActions.clearSelectedDateTime());
            }

            if (selectedSessionTime && 
                (selectedSessionTime.slotsRemaining < selectedValue ||
                selectedSessionTime.openTimeMinimumPlayers > selectedValue)) {
                dispatch(SessionActions.clearSelectedTime());
            }

            dispatch(PlayerActions.selectPlayers(selectedValue));
            if(selectedDate) {
                dispatch(SessionActions.loadSessionTimes(moment(selectedDate), selectedGame?.experienceId));
            }
        },
        [pendingBooking, bookingStatus, selectedSessionTime, selectedDate, selectedGame, dispatch]
    );

    const panelOpened = useCallback(
        (isOpen: boolean) => {
            if (isOpen)
                dispatch({
                    type: 'VIEWED_GROUP_INFO',
                    googleTagManagerEvent: GoogleTagManagerEvents.ViewedGroupEnquiry(),
                } as IAnalyticsAction);
        },
        [dispatch]
    );

    const groupEventClicked = useCallback(() => {
        dispatch({
            type: 'SENT_GROUP_ENQUIRY',
            googleTagManagerEvent: GoogleTagManagerEvents.SentGroupEventEnquiry(),
        } as IAnalyticsAction);
    }, [dispatch]);

    const NoAvailabilityState = (): React.ReactElement => {
        return (
            <Row justify="start" align="top">
                <Col span={24}>
                    <Empty
                        description={t('sessionDatePicker.labels.noDatesAvailable')}
                        image={emptyLogo}
                        imageStyle={{ opacity: 0.1, height: 50, margin: 20 }}
                    />
                </Col>
            </Row>
        );
    };

    if (isLoading) return <LoadingSpinner />;
    else if (!isOpen) return <NoAvailabilityState />;

    return (
        <React.Fragment>
            <Row justify="start" align="top">
                <Col span={24}>
                    <NumberPicker
                        locale={locale}
                        playerCount={selectedPlayers ?? 0}
                        min={1}
                        max={maximumPlayers}
                        onChange={playersSelected}
                    />
                </Col>
            </Row>
            <Row justify="start" align="top">
                <Col>
                    <Hint panelKey="morethan8" label={t(selectedPurchaseType === PurchaseTypes.PrivateEvent ? 'playerPicker.hint.moreThan8' : 'playerPicker.hint.moreThan8PrivateEvent', { maximumPlayers: maximumPlayers })} onClick={panelOpened}>
                        <Trans i18nKey={selectedPurchaseType === PurchaseTypes.PrivateEvent ? "playerPicker.hint.maxCapacity" : "playerPicker.hint.maxCapacityPrivateEvent"} values={{ maximumPlayers: maximumPlayers }}>
                            {createElement('a', 
                            { 
                                'class': 'ant-btn',
                                'href': selectedPurchaseType === PurchaseTypes.PrivateEvent || privateEventsAvailable === false ? groupBookingLink : privateEventsLink, 
                                'target': selectedPurchaseType === PurchaseTypes.PrivateEvent || privateEventsAvailable === false ? '_blank' : '_self'
                            })}
                        </Trans>
                    </Hint>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PlayerCount;
