import React from 'react';
import { Row, Col } from 'antd';
import Heading, { HeadingSize } from '../../components/heading/heading';
import SummaryModifyButton from './summary-modify-button';

export interface IHeaderSummaryProps {
    text: React.ReactNode;
    modify: boolean;
    modifyText: string;
    onModify: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const HeaderSummary: React.FunctionComponent<IHeaderSummaryProps> = (props) => {
    const { text, modify, modifyText, onModify } = props;

    if (!modify)
        return (
            <Row justify="start" align="top">
                <Col span={24}>
                    <Heading level={HeadingSize.Two}>{text}</Heading>
                </Col>
            </Row>
        );

    return (
        <Row justify="start" align="top">
            <Col span={16}>
                <Col span={24}>
                    <Heading level={HeadingSize.Two}>{text}</Heading>
                </Col>
            </Col>
            <Col span={8} className="modify">
                <SummaryModifyButton text={modifyText} onClick={onModify} />
            </Col>
        </Row>
    );
};

export default React.memo(HeaderSummary);
