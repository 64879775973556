import React from 'react';
import Icon from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import ZLButton, { ButtonSize, ButtonType } from '../zl-button/zl-button';
import { ReactComponent as checkmark } from '../../images/svg/icons-checkmark.svg';

export interface IGamePackageAddButtonProps {
    isSelected: boolean;
    label: string;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const GamePackageAddButton: React.FunctionComponent<IGamePackageAddButtonProps> = (props) => {
    return (
        <ZLButton
            type={ButtonType.Default}
            size={ButtonSize.Large}
            className={`add-button ${props.isSelected ? 'selected' : ''}`}
            onClick={props.onClick}
        >
            <Icon component={checkmark} className={`zl-icon ${props.isSelected ? 'selected' : ''}`} />
            <Text>{props.label}</Text>
        </ZLButton>
    );
};

export default React.memo(GamePackageAddButton);
