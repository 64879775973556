import i18n from '../i18n';
import moment from 'moment';
import { ZLLanguage } from './lang';

export const setLanguage = (language: ZLLanguage) => {
    if (language?.momentLocale !== 'en') require(`moment/locale/${language?.momentLocale}`);

    i18n.changeLanguage(language?.code ?? 'en-US');
    moment.locale(language?.momentLocale ?? 'en');
};
