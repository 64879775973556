import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators as GiftVoucherActions } from '../../store/GiftVoucher';
import ZLButton, { ButtonSize, ButtonType } from '../zl-button/zl-button';
import './recipient-selector.scss';


const RecipientSelector: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const toRecipient = useSelector((state: IApplicationState) => state.giftVoucher.toRecipientSelected);

    const isRecipientSelected = useCallback(
        (isRecipient: boolean) => {
            dispatch(GiftVoucherActions.setRecipient(isRecipient));
        },
        [dispatch, toRecipient]);

    return (
        <React.Fragment>
            <Row justify="start" align="top" className="zl-recipient-selector-container">
                <Col span={24}>
                    <ZLButton
                        type={ButtonType.Default}
                        selected={!(toRecipient ?? true)}
                        size={ButtonSize.Large}
                        className="zl-recipient-selector-button"
                        block
                        tall
                        onClick={() => isRecipientSelected(false)}
                        >
                        <div>
                            <Text>{t('giftVoucherSection.details.sendTicketsLabel')}</Text>
                            <p />
                            <Text>{t('giftVoucherSection.details.toMeLabel')}</Text>
                        </div>
                    </ZLButton>
                    <ZLButton
                        type={ButtonType.Default}
                        selected={toRecipient}
                        size={ButtonSize.Large}
                        className="zl-recipient-selector-button"
                        block
                        tall
                        onClick={() => isRecipientSelected(true)}
                    >
                        <div>
                            <Text>{t('giftVoucherSection.details.sendTicketsLabel')}</Text>
                            <p />
                            <Text>{t('giftVoucherSection.details.toRecipientLabel')}</Text>
                        </div>
                          
                    </ZLButton>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default RecipientSelector;