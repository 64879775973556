import React, { useCallback } from 'react';
import Session from '../../interfaces/ISession';
import { Modal } from 'antd';
import { ReactComponent as play } from '../../images/svg/icons-play.svg';
import Icon from '@ant-design/icons';
import loadable from '@loadable/component';
import LoadingSpinner from '../loading-spinner/loading-spinner';
import { useDispatch } from 'react-redux';
import { actionCreators as SessionActions } from '../../store/Sessions';

export interface IGamePackageImage {
    session?: Session;
    videoUrl?: string;
    imageUrl?: string;
    fallbackImage?: string;
    packageName: string;
    modalOkText: string;
}

const GamePackageImage: React.FunctionComponent<IGamePackageImage> = (props) => {
    const { session, videoUrl, imageUrl, fallbackImage, packageName, modalOkText } = props;
    const logo = `/static/zerolatency-new.png`;
    const ReactPlayerContainer = loadable(() => import('react-player'), { fallback: <LoadingSpinner /> });
    const dispatch = useDispatch();

    const playIconClicked = useCallback(() => {
        if (videoUrl) {
            const modal = Modal.info({
                icon: undefined,
                maskClosable: true,
                okText: modalOkText,
                autoFocusButton: null,
                okType: 'default',
                centered: true,
                title: '',
                className: 'player-container',
                content: <ReactPlayerContainer url={`${videoUrl}`} playing style={{ maxWidth: '100%' }} />,
                onOk: () => {
                    modal.destroy();
                },
                onCancel: () => {
                    modal.destroy();
                },
            });

            if(session) dispatch(SessionActions.postPackageVideoViewedAction(session));
        }
    }, [videoUrl, modalOkText]);

    const handleImageError = useCallback(
        (event) => {
            if (fallbackImage && event.currentTarget.src !== fallbackImage) {
                event.currentTarget.src = fallbackImage;
            } else {
                event.currentTarget.src = logo;
                event.currentTarget.className += ' no-image';
            }
        },
        [fallbackImage, logo]
    );

    const image =
        imageUrl === null || imageUrl === undefined
            ? fallbackImage === null || fallbackImage === undefined
                ? logo
                : fallbackImage
            : imageUrl;

    const playButton =
        videoUrl === undefined || videoUrl === null ? (
            <React.Fragment />
        ) : (
            <Icon component={play} className="play-icon" onClick={playIconClicked} />
        );

    const cssClass = image === logo ? 'no-image' : '';

    return (
        <div className="session-image">
            {playButton}
            <img src={image} alt={packageName} className={cssClass} onError={handleImageError} />
        </div>
    );
};

export default GamePackageImage;
