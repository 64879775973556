import './tickets.scss';
import { push } from 'connected-react-router';

import { Col, Row, List } from 'antd';
import Text from 'antd/lib/typography/Text';
import ZLSection from '../../containers/zl-section/zl-section';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useQuery from '../../hooks/useQuery';

import BookingSummary from '../../components/booking-summary/booking-summary';
import Heading, { HeadingSize } from '../../components/heading/heading';
import DatePickerSection from '../../page-sections/date-picker-section';
import PackagePickerSection from '../../page-sections/package-picker-section';
import PlayerPickerSection from '../../page-sections/player-picker-section';
import SitePickerSection from '../../page-sections/site-picker-section';
import TimePickerSection from '../../page-sections/time-picker-section';
import { actionCreators as PaymentActions } from '../../store/Payments';
import { actionCreators as BookingActions } from '../../store/Booking';
import { actionCreators as GiftVoucherActions, GiftVoucherStatus } from '../../store/GiftVoucher';
import { actionCreators as PurchaseTypeActions, PurchaseTypes } from '../../store/PurchaseTypes';
import { actionCreators as SiteActions } from '../../store/Sites';
import { actionCreators as GameActions } from '../../store/Games';
import { useParams } from 'react-router';
import { IApplicationState } from '../../store';
import { setLanguage } from '../../helpers/i18n';
import PurchaseTypeSection from '../../page-sections/gift-voucher/purchase-type-section';

import GiftVoucherAmountSection from '../../page-sections/gift-voucher/gift-voucher-amount-section';
import GiftVoucherDetailsSection from '../../page-sections/gift-voucher/gift-voucher-details-section';
import PrivateEventTypeSection from '../../page-sections/private-event-type-section';

export interface IAlertItemProps {
    alertText?: string;
    hidden?: boolean
}

const TicketsPage: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params: any = useParams();
    const query = useQuery();
    const location = useLocation();
    const [paramsAdded, setParamsAdded] = useState(false);
    const [queryAdded, setQueryAdded] = useState(false);

    const rtl = useSelector((state: IApplicationState) => state.intl.rtl);
    const purchaseSelectedUI = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseTypeUI)
    const purchaseAmountSelectedUI = useSelector((state: IApplicationState) => state.giftVoucher.selectedGiftVoucherAmountUI)
    const siteSelectedUI = useSelector((state: IApplicationState) => state.sites.selectedSiteUI);
    const playersSelectedUI = useSelector((state: IApplicationState) => state.players.selectedPlayersUI);
    const dateSelectedUI = useSelector((state: IApplicationState) => state.session.selectedDateUI);
    const timeSelectedUI = useSelector((state: IApplicationState) => state.session.selectedTimeUI);
    const site = useSelector((state: IApplicationState) => state.sites.selectedSite);

    const hasGiftVoucherAvailable = useSelector((state: IApplicationState) => state.sites.selectedSite?.giftVouchersAvailable);
    const useExternalBookingUrl = useSelector((state: IApplicationState) => state.sites.selectedSite?.useExternalBookingUrl);
    const giftVoucher = useSelector((state: IApplicationState) => state.giftVoucher.giftVoucher);
    const giftVoucherStatus = useSelector((state: IApplicationState) => state.giftVoucher.giftVoucher?.giftVoucherStatusTypeId);
    const selectedPurchaseType = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseType);
    const priceSets = useSelector((state: IApplicationState) => state.booking.priceSets);

    const giftVoucherAvailable = hasGiftVoucherAvailable && selectedPurchaseType === PurchaseTypes.GiftVoucher && !useExternalBookingUrl;

    const [forceEnglish, setforceEnglish] = useState(true);

    useEffect(() => {
        if (!paramsAdded) {
            //apply pre-selected venue name
            if (params && params.venue) {
                if (params.venue == 'melbourne') {
                    dispatch(push('/book-now/north-melbourne'));
                    dispatch(SiteActions.preselectSite('north-melbourne'));
                } else {
                    dispatch(SiteActions.preselectSite(params.venue));
                }
            }

            //apply access code
            if (params && params.accessCode) dispatch(SiteActions.addAccessCode(params.accessCode));

            setParamsAdded(true);
        }
    }, [dispatch, params, paramsAdded]);

    useEffect(() => {
        //only one code at a time
        if (!queryAdded) {
            const giftVoucherParam = process.env.REACT_APP_GIFTVOUCHER_PARAM ?? 'gv';
            const promoCodeParam = process.env.REACT_APP_PROMOCODE_PARAM ?? 'promo';
            const gameParam = process.env.REACT_APP_GAME_PARAM ?? 'game';
            const priceSetParam = 'set';

            if (query.get(giftVoucherParam))
                dispatch(BookingActions.recordPreselectedCode(query.get(giftVoucherParam)));
            else if (query.get(promoCodeParam))
                dispatch(BookingActions.recordPreselectedCode(query.get(promoCodeParam)));

            if (query.get(gameParam))
                dispatch(GameActions.preselectGame(query.get(gameParam)));

            if (query.get(priceSetParam))
                dispatch(BookingActions.recordPriceSet(params.venue, query.get(priceSetParam), 'querystring'));

            setQueryAdded(true);
        }
    }, [dispatch, query, queryAdded, params]);

    useEffect(() => {
        dispatch(SiteActions.loadSites());
        dispatch(PaymentActions.clearClientSecret());
    }, [dispatch]);

    useEffect(() => {

        if (giftVoucher && giftVoucherStatus !== GiftVoucherStatus.Pending) {
            dispatch(GiftVoucherActions.clearGiftVoucher());
            dispatch(PurchaseTypeActions.clearPurchaseType());
        }
    }, [dispatch, giftVoucherStatus, selectedPurchaseType, giftVoucher]); 

    if (forceEnglish) {
        setLanguage({
            code: 'en-US', displayText: 'English (US)', rtl: false, momentLocale: 'en'
        });
        setforceEnglish(false);
    }

    const AlertItem: React.FunctionComponent<IAlertItemProps> = (props) => {
        return (
            <ZLSection hidden={props.hidden} active={!props.hidden} alert={true}>
                <Row align="middle" justify="start" className="alert-info">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Text><span dangerouslySetInnerHTML={{ __html: props.alertText ?? "" }} /></Text>
                    </Col>
                </Row>
            </ZLSection>
        );
    };

    const priceSet = priceSets?.find((p) => p.venue === site?.urlKey);
    const hideAlert = site?.bookingAlert == null || site?.bookingAlert.length == 0 || priceSet !== undefined;
    const alertText = site?.bookingAlert;

    const hideBookingSection = !((selectedPurchaseType === PurchaseTypes.Booking || selectedPurchaseType === PurchaseTypes.PrivateEvent) && siteSelectedUI);

    const privateEventTypeSection = selectedPurchaseType === PurchaseTypes.PrivateEvent ? <PrivateEventTypeSection active={selectedPurchaseType === PurchaseTypes.PrivateEvent} scrollTo={true} /> : undefined;

    return (
        <React.Fragment>
            <Row justify="start" align="top">
                <Col>
                    <Heading level={HeadingSize.One} className="heading-indent">
                        {t('pageTitles.selectTickets')}
                    </Heading>
                </Col>
            </Row>
            <Row justify="center" align="top">
                <Col xs={24} sm={24} md={24} lg={18}>
                    <SitePickerSection active={true}/>
                    <AlertItem hidden={hideAlert} alertText={alertText} />
                    <PurchaseTypeSection active={siteSelectedUI} scrollTo={true} />
                    <div hidden={hideBookingSection}>
                        {privateEventTypeSection}
                        <PlayerPickerSection
                            active={(purchaseSelectedUI ?? false)
                                && (selectedPurchaseType === PurchaseTypes.Booking || selectedPurchaseType === PurchaseTypes.PrivateEvent)}
                            scrollTo={selectedPurchaseType === PurchaseTypes.Booking} />
                        <DatePickerSection active={playersSelectedUI ?? false} scrollTo={false} />
                        <TimePickerSection active={dateSelectedUI ?? false} scrollTo={true} />
                        <PackagePickerSection active={timeSelectedUI ?? false} scrollTo={true} />
                    </div>
                    <div hidden={!giftVoucherAvailable}> 
                        <GiftVoucherAmountSection
                            active={(purchaseSelectedUI ?? false)
                                && selectedPurchaseType === PurchaseTypes.GiftVoucher}
                            scrollTo={true} />
                        <GiftVoucherDetailsSection active={purchaseAmountSelectedUI ?? false} scrollTo={true} />
                    </div>
                    <BookingSummary drawer={true} />
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={6}>
                    <BookingSummary affix={true} />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default TicketsPage;
