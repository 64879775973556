import React from 'react';
import Text from 'antd/lib/typography/Text';
import Heading, { HeadingSize } from '../heading/heading';
import { Tag } from 'antd';

export interface IGamePackageHeaderProps {
    packageName: string;
    accessCode?: string;
    tagline: string;
}

const GamePackageHeader: React.FunctionComponent<IGamePackageHeaderProps> = (props) => {
    const tag = props.accessCode ? (
        <React.Fragment>
            <Tag color="#38bcee">{props.accessCode}</Tag>
            <br />
        </React.Fragment>
    ) : (
        <React.Fragment />
    );

    return (
        <div className="session-header-container">
            {tag}
            <Text className="package-subtitle">{props.tagline}</Text>
            <Heading className="package-title" level={HeadingSize.Two}>
                {props.packageName}
            </Heading>
        </div>
    );
};

export default GamePackageHeader;
