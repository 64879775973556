import { Action, Reducer } from 'redux';
import { IApplicationState, IAppThunkAction, IAnalyticsAction } from '.';
import Experience from '../interfaces/IExperience';
import { BOOKING_OPTIONS_LOADED, IBookingOptionsAction } from './Sites';
import { GoogleTagManagerEvents } from '../helpers/googleTagManager';

export interface IGameState {
    preselectedGame?: string;
    selectedGame?: Experience;
    games: Array<Experience>;
    gamesLoading: boolean;
    gamesLoaded: boolean;
}

export const GAME_SELECTED: 'GAME_SELECTED' = 'GAME_SELECTED';
export const GAMES_LOADING: 'GAMES_LOADING' = 'GAMES_LOADING';
export const GAMES_LOADED: 'GAMES_LOADED' = 'GAMES_LOADED';
export const PRESELECT_GAME: 'PRESELECT_GAME' = 'PRESELECT_GAME';

export interface IPreselectGameAction extends Action {
    type: typeof PRESELECT_GAME;
    game: string;
}

export interface IGameSelectedAction extends IAnalyticsAction {
    type: typeof GAME_SELECTED;
    game: Experience;
}

export interface IGamesLoadingAction extends Action {
    type: typeof GAMES_LOADING;
}

export interface IGamesLoadedAction extends Action {
    type: typeof GAMES_LOADED;
    games: Array<Experience>;
    preSelectedGame?: Experience;
}

export type KnownGameActions =
    IGameSelectedAction
    | IGamesLoadingAction
    | IGamesLoadedAction
    | IPreselectGameAction
    | IBookingOptionsAction;

export const actionCreators = {
    preselectGame: (game: string | null): IAppThunkAction<KnownGameActions> => (dispatch, getState) => {
        dispatch({ type: PRESELECT_GAME, game: game?.toLowerCase() ?? '' });
    },

    gameSelected: (game: Experience | undefined): IAppThunkAction<KnownGameActions> => (
        dispatch: any,
        getState: any
    ) => {
        dispatch({
            type: GAME_SELECTED,
            game: game,
            googleTagManagerEvent: GoogleTagManagerEvents.GameSelected(game),
        } as IGameSelectedAction);
    },
};

const unloadedState: IGameState = {
    gamesLoading: false,
    games: [],
    selectedGame: undefined,
    gamesLoaded: false
};

export const reducer: Reducer<IGameState> = (state: IGameState | undefined, incomingAction: Action): IGameState => {
    if (state === undefined) return unloadedState;

    const action: KnownGameActions = incomingAction as KnownGameActions;
    switch (action.type) {
        case GAMES_LOADING:
            return {
                preselectedGame: state.preselectedGame,
                selectedGame: state.selectedGame,
                gamesLoading: true,
                gamesLoaded: false,
                games: state.games
            };
        case GAMES_LOADED:
            return {
                preselectedGame: state.preselectedGame,
                selectedGame: state.selectedGame,
                gamesLoading: false,
                gamesLoaded: true,
                games: action.games
            };
        case GAME_SELECTED:
            return {
                preselectedGame: state.preselectedGame,
                selectedGame: action.game,
                gamesLoading: false,
                games: state.games,
                gamesLoaded: true
            };
        case PRESELECT_GAME:
            return {
                preselectedGame: action.game,
                selectedGame: state.selectedGame,
                gamesLoading: state.gamesLoading,
                gamesLoaded: state.gamesLoaded,
                games: state.games
            };
        case BOOKING_OPTIONS_LOADED:
            return {
                preselectedGame: state.preselectedGame,
                selectedGame: state.selectedGame,
                gamesLoading: state.gamesLoading,
                gamesLoaded: state.gamesLoaded,
                games: action.bookingOptions.experiences
            };
        default:
            return state;
    }
};
