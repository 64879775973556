import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SessionTimePicker from '../containers/session-time-picker/session-time-picker';
import ZLSection from '../containers/zl-section/zl-section';

export interface ITimePickerSectionProps {
    active: boolean;
    scrollTo?: boolean;
}

const TimePickerSection: React.FunctionComponent<ITimePickerSectionProps> = (props) => {
    const { t } = useTranslation();
    const { active, scrollTo } = props;

    return (
        <ZLSection title={t('sessionTimePicker.titles.chooseTime')} active={active} scrollTo={scrollTo}>
            <Row justify="start" align="top">
                <Col xs={24}>
                    <SessionTimePicker />
                </Col>
            </Row>
        </ZLSection>
    );
};

export default TimePickerSection;
