import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ZLSection from '../containers/zl-section/zl-section';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../store';
import PrivateEventTypePicker from '../components/private-event-type-picker/private-event-type-picker';

export interface ISitePickerSectionProps {
    active: boolean;
    scrollTo?: boolean;
}

const PrivateEventTypeSection: React.FunctionComponent<ISitePickerSectionProps> = (props) => {
    const { t } = useTranslation();
    const { active, scrollTo } = props;
    const loading = useSelector((state: IApplicationState) => state.sites.sitesLoading);

    return (
        <ZLSection title={t('privateEventTypePicker.titles.chooseEventType')} active={active} scrollTo={scrollTo}>
            <Row justify="start" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <PrivateEventTypePicker loading={loading} />
                </Col>
            </Row>
        </ZLSection>
    );
};

export default PrivateEventTypeSection;
