import './zl-section.scss';

import React, { useMemo, useEffect } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import Heading, { HeadingSize } from '../../components/heading/heading';
import { Row, Col } from 'antd';

export interface IZLSectionProps {
    title?: string;
    active?: boolean;
    children: React.ReactNode;
    suffix?: React.ReactNode;
    action?: React.ReactNode;
    scrollTo?: boolean;
    hidden?: boolean;
    alert?: boolean;
    noPadding?: boolean;
    black?: boolean;
}

const ZLSection: React.FunctionComponent<IZLSectionProps> = (props) => {
    const { title, active, children, suffix, action, scrollTo, noPadding, black } = props;

    const activeClass = active === true ? 'active' : '';
    const suffixed = suffix !== undefined ? 'suffixed' : '';
    const alertClass = props.alert ? 'booking-alert' : '';
    const noPaddingClass = noPadding ? 'no-padding' : '';
    const blackClass = black ? 'black' : '';
    const scrollDelay = 250;

    const scrollRef: React.RefObject<HTMLElement> = useMemo(() => {
        return React.createRef();
    }, []);

    useEffect(() => {
        const node = scrollRef.current;
        const scrollIntoViewSmoothly =
            'scrollBehavior' in document.documentElement.style ? scrollIntoView : smoothScrollIntoView;

        if (node && active && scrollTo) {
            setTimeout(() => {
                scrollIntoViewSmoothly(node, { behavior: 'smooth', block: 'start' });
            }, scrollDelay);
        }
    }, [active, scrollRef, scrollDelay, scrollTo]);

    if(props.hidden) {
        return <React.Fragment />;
    }

    const SectionHeading: React.FunctionComponent = () => {
        if (title === undefined || title === '') return <React.Fragment />;

        if (action !== undefined && active)
            return (
                <Row justify="start" align="top">
                    <Col span={16}>
                        <Heading level={HeadingSize.Two} className="section-title">
                            {title}
                        </Heading>
                    </Col>
                    <Col span={8} className="section-action">
                        {action}
                    </Col>
                </Row>
            );

        return (
            <Row justify="start" align="top">
                <Col span={24}>
                    <Heading level={HeadingSize.Two} className="section-title">
                        {title}
                    </Heading>
                </Col>
            </Row>
        );
    };

    return (
        <section className={`zl-section ${activeClass} ${suffixed} ${alertClass} ${noPaddingClass} ${blackClass}`} ref={scrollRef}>
            <SectionHeading />
            <div hidden={!active} className={`section-container ${noPaddingClass}`}>{children}</div>
            <div hidden={!active} className={`section-suffix`}>{suffix}</div>
        </section>
    );
};

export default ZLSection;
