import moment from 'moment';

import ISession from '../interfaces/ISession';
import ISite from '../interfaces/ISite';
import errorHelpers from '../helpers/errors';

class Sessions {
    static GetSessionTimes = (
        site: ISite,
        date: moment.Moment,
        players: number | undefined,
        accessCode: string | undefined,
        experienceId: number | undefined,
        packageTypeId: number | undefined,
        priceCode: string | undefined
    ): Promise<Array<ISession>> => {
        const selectedDate: string = date.format('YYYY-MM-DD');
        const route = `sessions/${site.siteId}/${selectedDate}/${accessCode ?? ''}?experienceId=${experienceId ? experienceId : ''}&players=${players ? players : ''}&packageTypeId=${packageTypeId ? packageTypeId : ''}&priceCode=${priceCode ? priceCode : ''}`;

        return fetch(route)
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<Array<ISession>>);
    };
}

export default Sessions;
