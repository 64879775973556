import './loader.scss';

import React from 'react';

const ZLLoader: React.FunctionComponent = () => {
    const logo = `/static/zerolatency-new.png`;
    return (
        <div className="zl-loader">
            <img src={logo} alt="Zero Latency Free Roam Virtual Reality" />
        </div>
    );
};

export default ZLLoader;
