import './game-picker.scss';

import { Col, Row } from 'antd';
import find from 'lodash/find';
import moment from 'moment';
import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Experience from '../../interfaces/IExperience';
import { IApplicationState } from '../../store';
import { actionCreators as SessionActions } from '../../store/Sessions';
import { actionCreators as GameActions } from '../../store/Games';
import GameDropdown from '../game-dropdown/game-dropdown';
import LoadingSpinner from '../loading-spinner/loading-spinner';
import GameInfo from '../game-package/game-info';
import { PurchaseTypes } from '../../store/PurchaseTypes';
import { actionCreators as BookingActions, BookingStatus } from '../../store/Booking';

export interface IGamePickerProps {
    loading: boolean;
}

const GamePicker: React.FunctionComponent<IGamePickerProps> = (props) => {
    const { loading } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const games = useSelector((state: IApplicationState) => state.games.games);
    const selectedGame = useSelector((state: IApplicationState) => state.games.selectedGame);
    const preSelectedGame = useSelector((state: IApplicationState) => state.games.preselectedGame);
    const selectedSite = useSelector((state: IApplicationState) => state.sites.selectedSite);
    const currentLanguage = useSelector((state: IApplicationState) => state.intl.currentLocale);
    const pendingBooking = useSelector((state: IApplicationState) => state.booking.booking);
    const bookingStatus = useSelector((state: IApplicationState) => state.booking.status);

    const accessCode = useSelector((state: IApplicationState) => state.sites.accessCode);
    const selectedPurchaseType = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseType);
    const packageTypeId = selectedPurchaseType == PurchaseTypes.Booking ? 1 : 2;

    const gameChanged = useCallback(
        (game: Experience | undefined) => {
            dispatch(GameActions.gameSelected(game));

            if (selectedSite !== undefined) {
                const start: string = moment().locale('en').format('YYYY-MM-DD');
                const end: string = moment().locale('en').add(2, 'months').endOf('month').format('YYYY-MM-DD');
                dispatch(SessionActions.loadOpenDates(selectedSite.siteId, start, end, accessCode, game?.experienceId, packageTypeId));

                if (pendingBooking && bookingStatus === BookingStatus.Pending) {
                    dispatch(BookingActions.expireBooking());
                    dispatch(SessionActions.clearSelectedDateTime());
                }
            }
        },
        [preSelectedGame, selectedSite, dispatch, t, pendingBooking, bookingStatus, accessCode, packageTypeId]
    );

    const gamesDropdownChanged = (value: number) => {
        const game: Experience | undefined = find(games, (game: any) => parseInt(game.experienceId) === value);

        gameChanged(game);
    };

    useEffect(() => {
        if (selectedGame === undefined && preSelectedGame && games) {
            const targetGame = games.find((x) => x.alias.toLowerCase() === preSelectedGame);
            if (targetGame) gameChanged(targetGame);
        }
    }, [preSelectedGame, games, gameChanged, selectedGame]);

    if (loading) return <LoadingSpinner />;

    return (
        <React.Fragment>
            <Row justify="start" align="top" style={{marginBottom: '8px'}}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <GameDropdown
                        games={games}
                        selectedGame={selectedGame}
                        onChange={gamesDropdownChanged}
                        value={selectedGame?.experienceId ?? undefined}
                        placeholder={t('gamePicker.control.placeholder')}
                    />
                </Col>
            </Row>
            <GameInfo game={selectedGame} locale={currentLanguage} site={selectedSite} />
        </React.Fragment>
    );
};

export default GamePicker;
