import { Action, Reducer } from 'redux';
import AppSettings from '../service/appsettings'
import { IAppSettingsList } from '../service/appsettings'
import { IApplicationState, IAppThunkAction, IAnalyticsAction } from '.';
import Logging from '../service/logging';
import { actionCreators as UIActions } from '../store/UI';
// import { appInsights } from "../Logging/appInsights";


export interface IAppSettingsState {
    gtmEndpoint: string;
    gtmSendToClients: boolean;
    environment: string;
    logRocketId: string;
    brazeApiKey: string;
    brazeEndpoint: string;
    newsletterEmailGroup: string,
    updatesEmailGroup: string,
    offersEmailGroup: string,
    auSmsGroup: string,
    settingsLoaded: boolean;
    appInsightsKey: string;
}

const unloadedState: IAppSettingsState = {
    settingsLoaded: false,
    gtmEndpoint: "",
    gtmSendToClients: false,
    environment: "",
    logRocketId: "",
    brazeApiKey: "",
    brazeEndpoint: "",
    newsletterEmailGroup: "",
    updatesEmailGroup: "",
    offersEmailGroup: "",
    auSmsGroup: "",
    appInsightsKey: ""
};

export const APP_SETTINGS_LOADED: 'APP_SETTINGS_LOADED' = 'APP_SETTINGS_LOADED';
export const APP_SETTINGS_LOADING: 'APP_SETTINGS_LOADING' = 'APP_SETTINGS_LOADING';

export interface IAppSettingsLoaded extends Action {
    type: typeof APP_SETTINGS_LOADED;
    settings: IAppSettingsState;
}

export interface IAppSettingsLoading extends Action {
    type: typeof APP_SETTINGS_LOADING;
}

export type KnownActions =
    | IAppSettingsLoaded
    | IAppSettingsLoading;

export const reducer: Reducer<IAppSettingsState> = (state: IAppSettingsState | undefined, incomingAction: Action): IAppSettingsState => {
    if (state === undefined) return unloadedState;

    const action: KnownActions = incomingAction as KnownActions;
    switch (action.type) {
        case APP_SETTINGS_LOADED:
            return {
                ...state,
                settingsLoaded: true
            };
        default:
            return state;
    }
};


export const actionCreators = {
    loadSettings: (): IAppThunkAction<KnownActions> => (dispatch: any, getState: any) => {
        const appState: IApplicationState = getState();
        if (appState && !appState.appSettings?.settingsLoaded) {
            AppSettings.GetAppSettings()
                .then((data: IAppSettingsList) => {

                    var copyOfSettings = appState.appSettings;

                    copyOfSettings.gtmEndpoint = data.gtmEndpoint;
                    copyOfSettings.gtmSendToClients = data.gtmSendToClients;
                    copyOfSettings.environment = data.environment;
                    copyOfSettings.logRocketId = data.logRocketId;
                    copyOfSettings.brazeApiKey = data.brazeApiKey;
                    copyOfSettings.brazeEndpoint = data.brazeEndpoint;
                    copyOfSettings.newsletterEmailGroup = data.newsletterEmailGroup;
                    copyOfSettings.updatesEmailGroup = data.updatesEmailGroup;
                    copyOfSettings.offersEmailGroup = data.offersEmailGroup;
                    copyOfSettings.auSmsGroup = data.auSmsGroup;
                    copyOfSettings.appInsightsKey = data.appInsightsKey;

                    dispatch({ type: APP_SETTINGS_LOADED, settings: copyOfSettings });
                })
                .catch((error: Error) => {
                    Logging.LogError(error);
                    dispatch(UIActions.displayError('errors.errorOccurred'));

                    // appInsights?.trackException(
                    //     { error: new Error(`Error loading app settings`), severityLevel: 5}, 
                    //     { exception: error, sourceFile: "AppSettingsState.ts", product: "booking" });
                });

            dispatch({ type: APP_SETTINGS_LOADING });
        }
    }
}