import i18next from 'i18next';

const pluralHelper = {
    getKey: (lng: string, key: string, count: number): string => {
        i18next.services.pluralResolver.options.compatibilityJSON = 'v3';
        const rule = i18next.services.pluralResolver.getRule(lng);
        const suffix = i18next.services.pluralResolver.getSuffix(lng, count);

        //find the 18next git and read the pluralResolver code
        //const plurals = i18next.services.pluralResolver.getPluralFormsOfKey(lng, key);
        const output = rule && rule.numbers.length === 2 && suffix === '_1' ? `${key}_plural` : `${key}`;
        return output;
    },
};

export default pluralHelper;
