import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PackagePicker from '../containers/package-picker/package-picker';
import ZLSection from '../containers/zl-section/zl-section';
import Scroll from '../helpers/scroll';

export interface IPackagePickerSectionProps {
    active: boolean;
    scrollTo?: boolean;
}

const PackagePickerSection: React.FunctionComponent<IPackagePickerSectionProps> = (props) => {
    const { t } = useTranslation();
    const { active, scrollTo } = props;

    const scrollRef: React.RefObject<HTMLElement> = React.createRef();

    useEffect(() => {
        if (active) Scroll.scrollTo(scrollRef);
    }, [active, scrollRef]);

    return (
        <ZLSection title={t('packagePicker.titles.choosePackage')} active={active} scrollTo={scrollTo}>
            <Row justify="center" align="top">
                <Col xs={24}>
                    <PackagePicker />
                </Col>
            </Row>
        </ZLSection>
    );
};

export default PackagePickerSection;
