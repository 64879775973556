import { Action, Reducer } from 'redux';
import SitesService from '../service/sites';
import { IApplicationState, IAppThunkAction, IAnalyticsAction } from '.';
import ISite from '../interfaces/ISite';
import IBookingOptions from '../interfaces/IBookingOptions';
import { actionCreators as UIActions } from '../store/UI';
import { actionCreators as BookingActions } from '../store/Booking';
import ILocalisedContent from '../interfaces/ILocalisedContent';
import { GoogleTagManagerEvents } from '../helpers/googleTagManager';
// import { appInsights } from "../Logging/appInsights";

export interface ISiteState {
    accessCode?: string;
    preselectedSite?: string;
    selectedSite?: ISite;
    selectedSiteGtmKey?: string;
    bookingOptions?: IBookingOptions;
    nearestSite?: ISite;
    selectedSiteUI: boolean;
    sites: Array<ISite>;
    sitesLoading: boolean;
    sitesLoaded: boolean;
    policies?: Array<ILocalisedContent>;
}

export const ADD_ACCESS_CODE: 'ADD_ACCESS_CODE' = 'ADD_ACCESS_CODE';
export const NEAREST_SITE: 'NEAREST_SITE' = 'NEAREST_SITE';
export const SITE_SELECTED: 'SITE_SELECTED' = 'SITE_SELECTED';
export const SITES_LOADING: 'SITES_LOADING' = 'SITES_LOADING';
export const SITES_LOADED: 'SITES_LOADED' = 'SITES_LOADED';
export const PRESELECT_SITE: 'PRESELECT_SITE' = 'PRESELECT_SITE';
export const SITE_POLICIES_LOADING: 'SITE_POLICIES_LOADING' = 'SITE_POLICIES_LOADING';
export const SITE_POLICIES_LOADED: 'SITE_POLICIES_LOADED' = 'SITE_POLICIES_LOADED';
export const BOOKING_OPTIONS_LOADED: 'BOOKING_OPTIONS_LOADED' = 'BOOKING_OPTIONS_LOADED';

export interface IGetSitePoliciesAction extends Action {
    type: typeof SITE_POLICIES_LOADING;
}

export interface IGetSitePoliciesLoadedAction extends Action {
    type: typeof SITE_POLICIES_LOADED;
    policies: Array<ILocalisedContent>;
}

export interface IPreselectSiteAction extends Action {
    type: typeof PRESELECT_SITE;
    site: string;
}

export interface ISiteSelectedAction extends IAnalyticsAction {
    type: typeof SITE_SELECTED;
    site: ISite;
    selectedSiteGtmKey?: string;
    hasGiftVoucher: boolean;
}

export interface ISitesLoadingAction extends Action {
    type: typeof SITES_LOADING;
}

export interface ISitesLoadedAction extends Action {
    type: typeof SITES_LOADED;
    sites: Array<ISite>;
    preSelectedSite?: ISite;
}

export interface ISetNearestSiteAction extends IAnalyticsAction {
    type: typeof NEAREST_SITE;
    site: ISite;
    selectedSiteGtmKey?: string;
}

export interface IAddAccessCode extends Action {
    type: typeof ADD_ACCESS_CODE;
    accessCode: string;
}

export interface IBookingOptionsAction extends Action {
    type: typeof BOOKING_OPTIONS_LOADED;
    bookingOptions: IBookingOptions;
}

export type KnownSiteActions =
    | IAddAccessCode
    | ISiteSelectedAction
    | ISitesLoadingAction
    | ISitesLoadedAction
    | IGetSitePoliciesAction
    | IGetSitePoliciesLoadedAction
    | ISetNearestSiteAction
    | IPreselectSiteAction
    | IBookingOptionsAction;

export const actionCreators = {
    loadSites: (): IAppThunkAction<KnownSiteActions> => (dispatch: any, getState: any) => {
        const appState: IApplicationState = getState();
        if (appState && !appState.sites.sitesLoaded) {
            SitesService.GetSites()
                .then((data) => {
                    if (appState.sites.preselectedSite) {
                        const targetKey = appState.sites.preselectedSite;
                        const site = data.find((x) => x !== null && x.urlKey?.startsWith(targetKey));

                        dispatch({ type: SITES_LOADED, sites: data, preSelectedSite: site });
                    } else dispatch({ type: SITES_LOADED, sites: data });
                })
                .catch((error: Error) => {
                    dispatch(UIActions.displayError('errors.noSites'));
                    // appInsights?.trackException(
                    //     { error: new Error(`Error loading sites`), severityLevel: 5}, 
                    //     { exception: error, sourceFile: "Sites.ts", product: "booking" });
                });

            dispatch({ type: SITES_LOADING });
        }
    },

    loadSitePolicies: (): IAppThunkAction<KnownSiteActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();
        if (appState && appState.sites.selectedSite) {
            SitesService.GetSitePolicies(appState.sites.selectedSite.siteId)
                .then((data) => {
                    dispatch({ type: SITE_POLICIES_LOADED, policies: data });
                })

            dispatch({ type: SITE_POLICIES_LOADING });
        }
    },

    preselectSite: (site: string): IAppThunkAction<KnownSiteActions> => (dispatch, getState) => {
        dispatch({ type: PRESELECT_SITE, site: site.toLowerCase() });
    },

    addAccessCode: (accessCode: string): IAppThunkAction<KnownSiteActions> => (dispatch, getState) => {
        dispatch({ type: ADD_ACCESS_CODE, accessCode: accessCode.toLowerCase() });
    },

    siteSelected: (site: ISite, nearest: boolean): IAppThunkAction<KnownSiteActions> => (
        dispatch: any,
        getState: any
    ) => {
        // Create a new GTM Key if the site uses tag manager
        const gtmKey = site.siteMetadata.googleTagManagerKey ? `dataLayer${site.code.replace('-','')}` : undefined;

        if (nearest)
            dispatch({
                type: NEAREST_SITE,
                site: site,
                googleTagManagerEvent: GoogleTagManagerEvents.SiteSelected(site, true),
                selectedSiteGtmKey: gtmKey
            } as ISetNearestSiteAction);
        else
            dispatch({
                type: SITE_SELECTED,
                site: site,
                googleTagManagerEvent: GoogleTagManagerEvents.SiteSelected(site, false),
                selectedSiteGtmKey: gtmKey
            } as ISiteSelectedAction);
    },
    
    loadSiteBookingOptions: (site: ISite, key: string | undefined): IAppThunkAction<KnownSiteActions> => (
        dispatch: any,
        getState: any
    ) => {
        SitesService.GetSiteBookingOptions(site.siteId, key)
            .then((data): any => {
                dispatch({ type: BOOKING_OPTIONS_LOADED, bookingOptions: data });

                if(data.priceTierSet !== undefined) {
                    dispatch(BookingActions.recordPriceSet(site.urlKey, data.priceTierSet, 'server'));
                }
            })
    },
};

const unloadedState: ISiteState = {
    sitesLoading: false,
    sites: [],
    selectedSite: undefined,
    sitesLoaded: false,
    nearestSite: undefined,
    selectedSiteUI: false,
    bookingOptions: undefined,
};

export const reducer: Reducer<ISiteState> = (state: ISiteState | undefined, incomingAction: Action): ISiteState => {
    if (state === undefined) return unloadedState;

    const action: KnownSiteActions = incomingAction as KnownSiteActions;
    switch (action.type) {
        case SITES_LOADING:
            return {
                accessCode: state.accessCode,
                preselectedSite: state.preselectedSite,
                selectedSite: state.selectedSite,
                selectedSiteGtmKey: state.selectedSiteGtmKey,
                selectedSiteUI: state.selectedSiteUI,
                sitesLoading: true,
                sitesLoaded: false,
                sites: state.sites,
                nearestSite: state.nearestSite,
                policies: state.policies,
                bookingOptions: state.bookingOptions,
            };
        case SITES_LOADED:
            return {
                accessCode: state.accessCode,
                preselectedSite: state.preselectedSite,
                selectedSite: state.selectedSite,
                selectedSiteGtmKey: state.selectedSiteGtmKey,
                selectedSiteUI: state.selectedSiteUI,
                sitesLoading: false,
                sitesLoaded: true,
                sites: action.sites,
                nearestSite: state.nearestSite,
                policies: state.policies,
                bookingOptions: state.bookingOptions,
            };
        case SITE_SELECTED:
            return {
                accessCode: state.accessCode,
                preselectedSite: state.preselectedSite,
                selectedSite: action.site,
                selectedSiteGtmKey: action.selectedSiteGtmKey,
                selectedSiteUI: !action.site.useExternalBookingUrl,
                sitesLoading: false,
                sites: state.sites,
                sitesLoaded: true,
                nearestSite: state.nearestSite,
                policies: state.policies,
                bookingOptions: state.bookingOptions,
            };
        case NEAREST_SITE:
            return {
                accessCode: state.accessCode,
                preselectedSite: state.preselectedSite,
                selectedSite: action.site,
                selectedSiteGtmKey: action.selectedSiteGtmKey,
                selectedSiteUI: true,
                sitesLoading: state.sitesLoading,
                sitesLoaded: state.sitesLoaded,
                sites: state.sites,
                nearestSite: action.site,
                policies: state.policies,
                bookingOptions: state.bookingOptions,
            };
        case PRESELECT_SITE:
            return {
                accessCode: state.accessCode,
                preselectedSite: action.site,
                selectedSite: state.selectedSite,
                selectedSiteGtmKey: state.selectedSiteGtmKey,
                selectedSiteUI: true,
                sitesLoading: state.sitesLoading,
                sitesLoaded: state.sitesLoaded,
                sites: state.sites,
                nearestSite: state.nearestSite,
                policies: state.policies,
                bookingOptions: state.bookingOptions,
            };
        case SITE_POLICIES_LOADED:
            return {
                accessCode: state.accessCode,
                preselectedSite: state.preselectedSite,
                selectedSite: state.selectedSite,
                selectedSiteGtmKey: state.selectedSiteGtmKey,
                selectedSiteUI: true,
                sitesLoading: state.sitesLoading,
                sitesLoaded: state.sitesLoaded,
                sites: state.sites,
                nearestSite: state.nearestSite,
                policies: action.policies,
                bookingOptions: state.bookingOptions,
            };
        case ADD_ACCESS_CODE:
            return {
                accessCode: action.accessCode,
                preselectedSite: state.preselectedSite,
                selectedSite: state.selectedSite,
                selectedSiteGtmKey: state.selectedSiteGtmKey,
                selectedSiteUI: state.selectedSiteUI,
                sitesLoading: state.sitesLoading,
                sitesLoaded: state.sitesLoaded,
                sites: state.sites,
                nearestSite: state.nearestSite,
                policies: state.policies,
                bookingOptions: state.bookingOptions,
            };
        case BOOKING_OPTIONS_LOADED:
            return {
                accessCode: state.accessCode,
                preselectedSite: state.preselectedSite,
                selectedSite: state.selectedSite,
                selectedSiteGtmKey: state.selectedSiteGtmKey,
                selectedSiteUI: state.selectedSiteUI,
                sitesLoading: state.sitesLoading,
                sitesLoaded: state.sitesLoaded,
                sites: state.sites,
                nearestSite: state.nearestSite,
                policies: state.policies,
                bookingOptions: action.bookingOptions,
            };
        default:
            return state;
    }
};
