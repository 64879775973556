export enum PaymentGateway {
    PayPal = 'PayPal',
    Stripe = 'Stripe',
    Checkout = 'Checkout',
    None = 'None',
}

export default interface IPaymentGateway {
    apiKey: string;
    gateway: PaymentGateway;
    paymentMethodType: string;
}
