import React from 'react';
import SubtotalItem, { ISummarySubtotalItemProps } from './summary-subtotal-item';

export interface ISummarySubtotalProps {
    currency?: string;
    items: Array<ISummarySubtotalItemProps>;
    total: ISummarySubtotalItemProps;
    inclusiveTaxItem?: ISummarySubtotalItemProps;
    hide?: boolean;
}

const SummarySubtotal: React.FunctionComponent<ISummarySubtotalProps> = (props) => {
    const { currency, items, total, inclusiveTaxItem, hide } = props;

    if (items === undefined || items.length === 0) return <React.Fragment></React.Fragment>;
    if (currency === undefined || currency === '') return <React.Fragment></React.Fragment>;
    if (hide === true) return <React.Fragment></React.Fragment>;

    const taxItem =
        inclusiveTaxItem !== undefined ? <SubtotalItem {...inclusiveTaxItem} key={'tax'} /> : <React.Fragment />;

    return (
        <React.Fragment>
            <div className="subtotal">
                {items.map((subtotalItemProps, index) => {
                    return <SubtotalItem {...subtotalItemProps} key={subtotalItemProps.label} />;
                })}
            </div>
            <div className="total">
                <SubtotalItem {...total} key={'total'} />
                {taxItem}
            </div>
        </React.Fragment>
    );
};

export default React.memo(SummarySubtotal);
