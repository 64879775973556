import './location-finder.scss';

import Icon from '@ant-design/icons';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as locationSvg } from '../../images/svg/icons-arrow-newwindow.svg';
import * as LocationStore from '../../store/Location';
import Hint from '../hint/hint';
import ZLButton, { ButtonType } from '../zl-button/zl-button';

export interface IZLLocationProps {
    children: React.ReactNode | string;
}

const ZLLocation: React.FunctionComponent<IZLLocationProps> = (props) => {
    const dispatch = useDispatch();

    const clickButton = () => {
        dispatch(LocationStore.actionCreators.postGetLocationAction());
    };

    return (
        <Row justify="start" align="top">
            <Col span={24}>
                <Hint>
                    <ZLButton className="zl-location" onClick={clickButton} type={ButtonType.Link}>
                        <Text>{props.children}</Text>
                        <Icon className="zl-icon" component={locationSvg} />
                    </ZLButton>
                </Hint>
            </Col>
        </Row>
    );
};

export default ZLLocation;
