const numberHelpers = {
    toCurrency: (
        locale: string,
        currency: string,
        amount: number,
        useGrouping?: boolean | undefined,
        showCode?: boolean | undefined
    ) => {
        if (locale === undefined) return null;

        const options = {
            style: 'currency',
            currency: currency,
            currencyDisplay: showCode === true ? 'code' : 'symbol',
            useGrouping: useGrouping ?? true,
        };

        return new Intl.NumberFormat(locale, options).format(amount);
    },

    toNumber: (locale: string, value: number, style?: string | undefined, useGrouping?: boolean | undefined) => {
        if (locale === undefined) return null;

        const options = {
            style: style ?? 'decimal',
            useGrouping: useGrouping ?? true,
        };

        return new Intl.NumberFormat(locale, options).format(value);
    },
};

export default numberHelpers;
