import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SitePicker from '../components/site-picker/site-picker';
import ZLSection from '../containers/zl-section/zl-section';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../store';

export interface ISitePickerSectionProps {
    active: boolean;
    scrollTo?: boolean;
}

const SitePickerSection: React.FunctionComponent<ISitePickerSectionProps> = (props) => {
    const { t } = useTranslation();
    const { active, scrollTo } = props;
    const loading = useSelector((state: IApplicationState) => state.sites.sitesLoading);

    return (
        <ZLSection title={t('sitePicker.titles.findVenue')} active={active} scrollTo={scrollTo}>
            <Row justify="start" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <SitePicker loading={loading} />
                </Col>
            </Row>
        </ZLSection>
    );
};

export default SitePickerSection;
