import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import React, { useCallback } from 'react';
import numberHelpers from '../../helpers/number';
import { IGiftVoucherConfig } from '../../interfaces/IGiftVoucherConfig';
import ZLButton, { ButtonSize, ButtonType } from '../zl-button/zl-button';

export interface IVoucherAmountButtonProps {
    voucherConfig?: IGiftVoucherConfig;
    currencySymbol: string;
    number: number;
    disabled: boolean;
    selected: boolean;
    locale: string;
    discountPercentage?: number;
    onClick?: (amount: number, num: number) => void;
}

const VoucherAmountButton: React.FunctionComponent<IVoucherAmountButtonProps> = (props) => {
    const { t } = useTranslation();
    const numberStr = numberHelpers.toNumber(props.locale, props.number);
    const onClickEvent = props.onClick;
    const number = props.number;
    const voucherConfig = props.voucherConfig;
    const ticketAmount = voucherConfig?.price ?? 0;
    const buttonAmount = number * ticketAmount;
    const discountAmount = buttonAmount * (1 - (props.discountPercentage ?? 0));
    const buttonAmountStr = props.currencySymbol + buttonAmount.toFixed(2).toString();
    const buttonDiscountStr = props.currencySymbol + discountAmount.toFixed(2).toString();

    const numberOfTicketsString = props.number > 1
        ? numberStr + " " + t('giftVoucherSection.purchase.multipleTicketLabel')
        : numberStr + " " + t('giftVoucherSection.purchase.singleTicketLabel');

    const numberClicked = useCallback(() => {
        if (onClickEvent) onClickEvent(buttonAmount, number);
    }, [onClickEvent, ticketAmount]);

    return (
        <ZLButton
            type={ButtonType.Default}
            selected={props.selected}
            size={ButtonSize.Large}
            disabled={props.disabled}
            className="zl-voucher-amount-button"
            block
            tall
            onClick={numberClicked}
        >
            <div style={{whiteSpace: "normal"}}>
                <Text>{numberOfTicketsString}</Text>
                {props.discountPercentage && <Text className='zl-discount-amount'>{t('giftVoucherSection.purchase.percentOff', { percentage: props.discountPercentage * 100 })}</Text>}
                <p />
                {props.discountPercentage ? <Text className='zl-discounted-value'>{t('giftVoucherSection.purchase.valueFor', { credit: buttonAmountStr, price: buttonDiscountStr })}</Text> : <Text>{buttonAmountStr}</Text>}
            </div>
        </ZLButton>
    );
};

export default React.memo(VoucherAmountButton);
